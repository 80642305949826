import { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { csvJSonArray } from '../../../../common/utils/cesiumDataHandler';
import { JulianDate } from "cesium";
import { getTailNumber, getStartTime } from '../debrief/debriefPlayer/DebriefPlayer';

const Plotlygraph = (props:any) => {

  const funcHolder: any = {};

  // State for storing data and layout for the Plotly graph
  const [data, setData] = useState<any[] | undefined>(undefined);
  const [layout, setLayout] = useState<any | undefined>(undefined);
  // State for storing the initial layout
  const [initialLayout, setInitialLayout] = useState<any | undefined>(undefined);


  // Effect to update the graph data and layout when the input arrays change
  useEffect(() => {

    const updateTooltip = () => {
      const anchorElement = document.querySelector('.modebar-btn[data-title="Download plot as a png"]');
      if (anchorElement) {
        anchorElement.setAttribute('data-title', 'Download as an Image');
      }
    };

    // Trace for the first set of data (IAS)
    const trace1 = {
      x: props.xaxisArray,
      y: props.y1axisArray,
      fill: 'tonexty',
      fillcolor: 'rgba(245, 106, 24, 0.2)',
      type: 'scatter',
      name: 'IAS', // Legend label for the first trace
      line: {
        color: '#ff9354',
      },
    };

    // Trace for the second set of data (AltMSL)
    const trace2 = {
      x: props.xaxisArray,
      y: props.y2axisArray,
      yaxis: 'y2', // Associate with the secondary y-axis
      fill: 'tonexty',
      fillcolor: 'rgba(120, 86, 255, 0.2)',
      type: 'scatter',
      name: 'AltMSL', // Legend label for the second trace
      line: {
        color: '#7856ff',
      },
    };

    // Combine the traces into an array for Plotly data
    const graphData = [trace1, trace2];

    // Layout configuration for the Plotly graph
    const graphLayout = {
      dragmode: false,
      xaxis: 
      {
        title: 'Local Time (HH:MM:SS)',
        range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)],
        tickformat: '%H:%M:%S',
      },
      yaxis: 
      {
        title: 'IAS (kn)',
        side: 'left',
        range: [Math.min(...props.y1axisArray), Math.max(...props.y1axisArray)],
      },
      yaxis2: {
        title: 'AltMSL (ft)',
        side: 'right',
        overlaying: 'y',
        range: [Math.min(...props.y2axisArray), Math.max(...props.y2axisArray)],
      },
      hovermode: 'closest',
      autosize: true,
      legend: {
        x: 0.455,
        y: 1.22,
        orientation: 'h',
      },
    };

    // Save the initial layout when the component first loads
    if (!initialLayout) {
      setInitialLayout(graphLayout);
    }

    // Update the state with the new data and layout
    setData(graphData);
    setLayout(graphLayout);

    const addSecondsToDate = (date: any, secondsToAdd: any) => {
      const newDate = new Date(date);
      newDate.setSeconds(date.getSeconds() + secondsToAdd)
      return newDate;
    }

    if (props.viewer) {
      const maxVal = Math.max(Math.max(...props.y1axisArray), Math.max(...props.y2axisArray))

      const clockEventListener = (clock: any) => {
        let dateObject = new Date();
        let diffSeconds = Math.round(JulianDate.secondsDifference(clock.currentTime, clock.startTime));
        dateObject = addSecondsToDate(props.xaxisArray[0], diffSeconds);
        const localTime = dateObject;

        const updatedVerticalLine = {
          type: 'line',
          x0: localTime,
          x1: localTime,
          y0: -1000,
          y1: maxVal,
          line: {
            color: 'red',
            width: 2,
            dash: 'dashdot',
          },
        };

        // setLayout((prevLayout: any) => {
        //   const shapes = [updatedVerticalLine];
        //   return { ...prevLayout, shapes };
        // });

        if (!props.isReportBeingGeneratedRef.current) {
          setLayout((prevLayout: any) => {
            const shapes = [updatedVerticalLine];
            return { ...prevLayout, shapes };
          });
        } else {
          setLayout((prevLayout: any) => ({
            ...prevLayout,
            shapes: [], // clear all shapes
          }));
        }

      }

      // Add the clock event listener
      const event = props.viewer.clock.onTick.addEventListener(clockEventListener);
      funcHolder["unsubScribeEventClock"] = event;

      // Clean up the event listener when the component unmounts
      return () => {
        if (funcHolder.unsubScribeEventClock) funcHolder.unsubScribeEventClock();
      };
    }

    updateTooltip();
    
  }, [csvJSonArray, props.viewer, props.xaxisArray, props.y1axisArray, props.y2axisArray]); // Dependencies for the useEffect

  // Function to handle double-click events on the graph
  const handleDoubleClick = () => {
    // Adjust the layout to fit the data range on double-click
    setLayout((initialLayout: any) => ({
      ...initialLayout,
      xaxis: { ...initialLayout.xaxis, range: [Math.min(...props.xaxisArray), Math.max(...props.xaxisArray)] },
      yaxis: { ...initialLayout.yaxis, range: [Math.min(...props.y1axisArray), Math.max(...props.y1axisArray)] },
      yaxis2: { ...initialLayout.yaxis2, range: [Math.min(...props.y2axisArray), Math.max(...props.y2axisArray)] },
    }));
  };

  // Reference for broadcasting zoom updates
  const broadcastZoom = useRef<(range: [number, number]) => void>();

  const handleRelayout = (event: any) => {
    // console.log(`Change detected: `, event);
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      const xRange = [event['xaxis.range[0]'], event['xaxis.range[1]']];
      if (broadcastZoom.current) {
        // console.log(`Inside IF: `, xRange);
        // broadcastZoom.current(xRange);
      }
    }
  };

  // useEffect(() => {
  //   broadcastZoom.current = (range: [number, number]) => {
  //     setLayout((prevLayout: any) => ({
  //       ...prevLayout,
  //       xaxis: { ...prevLayout.xaxis, range },
  //     }));
  //   };
  // }, []);

  // Render the Plotly graph component
  return (
    <Plot
      divId="plotly-graph"
      data={data as any}
      layout={layout as any}
      config={{
        doubleClick: false,
        responsive: true,
        displayModeBar: true,
        // scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d', 'lasso2d', 'select2d'],
        toImageButtonOptions: {
          format: `png`,
          filename: `${getTailNumber()}_IAS vs Alt vs Time_${getStartTime()}`,
        },
      }}
      useResizeHandler={true}
      onDoubleClick={handleDoubleClick}
      onRelayout={handleRelayout}
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default Plotlygraph;