import "./Import.scss";
import UploadLogs from "./components/uploadLogs/UploadLogs";
import UploadFolder from "./components/uploadLogs/UploadFolder";

import { useState } from 'react';

const Import = () => {

    const [activeTab, setActiveTab] = useState<string>('uploadLogs');
    // const [activeTab, setActiveTab] = useState<string>('uploadLogs');
    
    return(
        <>
        <div className="import-wraper">
            <div className="import-tab-heads">
                <h2>Flight Log Uploads</h2>
                <p>Manage, upload, and monitor your flight logs status efficiently all in one place</p>
                <div className="tab-button-wrapper">
                    <button onClick={() => setActiveTab('uploadLogs')} className={`tab-button-upload-logs ${activeTab === 'uploadLogs' ? 'tab-active' : ''}`}>Upload Logs</button>
                    {/* <button onClick={() => setActiveTab('uploadFolder')} className={`tab-button-upload-folder ${activeTab === 'uploadFolder' ? 'tab-active' : ''}`}>Upload Folder</button> */}

                    {/* Temporarily Commenting: Do Not Remove */}
                    {/* <button className="tab-button-upload-history">Upload History</button> */}
                    <div className="d-inline space"></div>
                </div>
            </div>
            {
                (activeTab === 'uploadLogs') &&
                <UploadLogs/>
            }
            {
                (activeTab === 'uploadFolder') &&
                <UploadFolder/>
            }
        </div>
        </>
    )
}

export default Import;