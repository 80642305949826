import { JulianDate } from "cesium";
import { GetJulianDateFromTimeStamp } from "./cesiumDataHandler";
import { PhaseColor } from "../../assets/styles/phaseColors";
import { BroadcastChannel } from "broadcast-channel";

export const TabDataBrodacast = new BroadcastChannel('TabDataBrodacast')
export const TabTimeSyncBroadcast = new BroadcastChannel('TabTimeSyncBroadcast')
export let segregatedDataToExport = []
export let analysedDataToExport = null

/**
 * Gets the file extension from the provided filename.
 * @param {String} filename - The filename from which to extract the extension.
 * @returns {String | undefined} - The lowercase file extension or undefined if not found.
 */
const getFileExtension = (filename: String) => {
  const extension = filename.split('.').pop()?.toLowerCase();
  return extension;
};


/**
 * Changes the date format from a given date.
 * @param {any} date - The date to be formatted.
 * @returns {string} - The formatted date string.
 */
export const changeDateFormat = (date: any) => {
  // try {

    const d = new Date(date);
    // console.log(`d: `, d)
    let str: any = d.toString().split(" ");
    // console.log(`str: `, str)
    if (str[0] !== `Invalid`) {
      let year: any = str[3].split("");
      let date1 = str[2] + " " + str[1] + " " + year[year.length - 2] + year[year.length - 1] + ", " + str[4] + " IST";
      return date1;
    } else {
      return ``;
    }
  // } catch (error) {}
};

/**
 * Gets the time from a given date.
 * @param {any} date - The date from which to extract the time.
 * @returns {string} - The formatted time string.
 */
export const getTime = (date: any) => {
  const d = new Date(date);
  let str: any = d.toString().split(" ");
  let time = str[4] + " IST";
  return time;
};

export default getFileExtension;


/**
 * Predefined columns used in the application.
 * @type {string[]}
 */
const predefinedColumns = ["AltMSL", "Latitude", "Longitude", "IAS", "Lcl Time", "Lcl Date", "AltGPS", "DateTimeUtc"];

/**
 * Additional columns for specific flight phases.
 * @type {Object}
 * @property {string[]} Taxi - Additional columns for the "Taxi" phase.
 * @property {string[]} Cruise - Additional columns for the "Cruise" phase.
 * @property {string[]} Takeoff - Additional columns for the "Takeoff" phase.
 * @property {string[]} Climb - Additional columns for the "Climb" phase.
 * @property {string[]} Criuse - Additional columns for the "Criuse" phase. (Note: Possible typo in "Criuse" - assuming it should be "Cruise")
 * @property {string[]} Descent - Additional columns for the "Descent" phase.
 * @property {string[]} Approach - Additional columns for the "Approach" phase.
 * @property {string[]} Landing - Additional columns for the "Landing" phase.
 */
const additionalColumnsJson: any = {
  "Taxi": ["GndSpd"],
  "Cruise": ["Pitch", "HDG", "GndSpd"],
  "Takeoff": ["VSpd", "Pitch", "HDG"],
  "Climb": ["VSpd", "TAS", "Roll", "Pitch"],
  "Criuse": ["HDG"],
  "Descent": ["VSpd", "GndSpd", "TAS", "Pitch", "NormAc"],
  "Approach": ["GndSpd", "VSpd", "Roll", "TAS"],
  "Landing": ["Pitch", "VSpd", "HDG", "NormAC"],
  "Touch Down": ["HDG"]
};
// const additionalColumnsJson: any = {
//   "Taxi": ["GndSpd"],
//   "Cruise": ["Pitch", "HDG", "GndSpd"],
//   "Takeoff": ["VSpd"],
//   "Climb": ["VSpd"],
//   "Criuse": ["HDG"],
//   "Descent": ["VSpd", "GndSpd"],
//   "Approach": ["GndSpd"],
//   "Landing": ["Pitch", "VSpd"]
// };


//fetch data for each segment
//additonal colums is an array of strings (additional columns example ["HDG"])
//startIndex and endIndex reffers to index of csvJsonArray
/**
 * Segregates data from the given CSV JSON array based on predefined and additional columns.
 * @param {Array} csvJsonArray - The CSV JSON array containing flight data.
 * @param {Array} additionalColumns - Additional columns specific to certain flight phases.
 * @param {number} startIndex - The index to start segregation from in the CSV JSON array.
 * @param {number} endIndex - The index to end segregation in the CSV JSON array.
 * @param {string} distFromRwy - JSON string containing distances from runway (optional).
 * @returns {Array} - Segregated data array based on predefined and additional columns.
 */
export const segregateData = (csvJsonArray: any, additionalColumns: string[], startIndex: number, endIndex: number, distFromRwy: any) => {
  let segragatedData = [];
  let distFromRwyArr = JSON.parse(distFromRwy)
  for (let index = startIndex; index <= endIndex; index++) {
    const element = csvJsonArray[index];
    if (element) {
      let data: any = {};
      predefinedColumns.map((e) => {
        data[e] = element[e];
      })
      additionalColumns.map((item: any) => {
        data[item] = element[item];
      })

      if (distFromRwy != null) {
        data["distFromRwy"] = distFromRwyArr[index - startIndex]
      }

      segragatedData.push(data);
    }
  }
  return segragatedData;
}


/**
 * Defines a list of graphs corresponding to different flight phases.
 */
const graphList: any = {
  "Taxi": ["GndSpd vs Time"],
  "Takeoff": ["VSpd vs Distance", "IAS vs Distance", "Alt vs Distance"],
  "Climb": ["IAS vs VSpd vs Time", "Alt vs Time"],
  "Cruise": ["Alt vs Time", "IAS vs GndSpd vs Time"],
  "Descent": ["Alt vs Time", "VSpd vs Time", "IAS vs GndSpd vs Time"],
  "Approach": ["Alt vs Time", "IAS vs GndSpd vs Time"],
  "Landing": ["Alt vs Distance", "IAS vs Distance", "VSpd vs Pitch vs Time", "Pitch vs Distance", "Alt vs IAS vs Time", "Pitch vs VSpd vs Distance"]
}


//Seggragates  data in segments 
//analysed data get from backend
//setseggregatedData is callback function which sets the value of seggregatedData
/**
 * Segregates the data based on the provided flight phases and additional columns.
 * @param {Array} csvJSonArray - The array of JSON objects representing the CSV data.
 * @param {Array} analysedData - The analysed data containing information about flight phases.
 * @param {Function} setseggregatedData - The state setter function for the segregated data.
 * @param {Function} setAnalysisData - The state setter function for analysis data.
 * @returns {void}
 */
export const segregateGraphData = async (csvJSonArray: any, analysedData: any, setseggregatedData: any, setAnalysisData: any) => {
  let segmentData: any = [];
  let airTime: number = 0;
  let timeLineBg: any = [];
  let flightDuration = 0;
  // console.log(`analysedData: `, analysedData)
  analysedData.graphData?.sort((a: any, b: any) => a.sequenceId > b.sequenceId);
  analysedDataToExport = analysedData;
  const graphDataLength = analysedData.graphData?.length;
  // console.log(`graphData: `, analysedData.graphData);
  // console.log(`graphData.length: `, analysedData.graphData.length);
  analysedData.graphData?.map((element: any) => {
    let indexJson: string = element.labelName;
    let additionalColumns: any = additionalColumnsJson[indexJson];
    let segregatedSegmentData = segregateData(csvJSonArray, additionalColumns || [], element.start, element.end + 1, element.distFromRwy)
    if (element.sequenceId === graphDataLength - 1) {
      segregatedSegmentData = segregateData(csvJSonArray, additionalColumns || [], element.start, element.end, element.distFromRwy)
    }
    // console.log(`element: `, element)
    if (!csvJSonArray[element.end]) {
      element.end = (element.end - 1)
    }
    // console.log('xxxxxxxxx')
    // console.log('xxxxxxxxx')
    // console.log('element.start: ', element.start)
    // console.log('element.end: ', element.end)
    
    let segement = {
      label: element.labelName,
      // data: segregateData(csvJSonArray, additionalColumns || [], element.start, element.end, element.distFromRwy),
      data: segregatedSegmentData,
      sequenceId: element.sequenceId,
      circuitNo: element.circuitNo,
      //duration:calculateDuration(GetJulianDate(csvJSonArray[element.start]), GetJulianDate(csvJSonArray[element.end])),
      duration: calculateDuration(GetJulianDateFromTimeStamp(csvJSonArray[element.start]), GetJulianDateFromTimeStamp(csvJSonArray[element.end])),
      isVisible: true,
      cesiumEntity3D: null,
      //cesiumEntity2D:null,
      labelId: element.labelId,
      graphList: graphList[element.labelName] || [],
      airportCode: element.airportCode,
      runway: element.runway,
      partitionIndex: element.partitionIndex || null,
      // subPhaseMapping: subPhasemapping(analysedData, element.sequenceId)
      subPhaseMapping: subPhasemapping(analysedData?.graphData, element.labelId, element.sequenceId)
    }
    let phaseDuration:number = 0;
    if (element.sequenceId === 0) {
      phaseDuration = JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start]))
    }else {
      phaseDuration = JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start-1]))
    }

    // console.log(`Phase DUration of ${element.sequenceId} is: `, phaseDuration)
    
    segmentData.push(segement);
    if (element.labelName !== 'Taxi' && element.labelId < 8) {
      let time: number = phaseDuration
      airTime = airTime + time
    }
    
    if (element.labelId < 8) {
      if (element.sequenceId === 0) {
        flightDuration += JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start]))
      } else {
        flightDuration += JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start-1]))
      }
      timeLineBg.push({
        sequenceId: element.sequenceId,
        phaseColor: phaseColor(element.labelName),
        duration: phaseDuration
      })
    }
  })

  // console.log(`airTime: `, airTime)
  setAnalysisData({ airTime: secondsToHms(airTime), noOfTakeOffs: analysedData?.analysisData[0]?.takeoffs, timeLineBgString: phasePercentage(timeLineBg, flightDuration) })
  
  //segmentData.sort((a:any,b:any) =>a.sequenceId >b.sequenceId)
  segmentData.sort((a: any, b: any) => parseFloat(a.sequenceId) - parseFloat(b.sequenceId));
  setseggregatedData(segmentData);
  // console.log(`segmentData: `, segmentData)
  segregatedDataToExport = segmentData;
  TabDataBrodacast.postMessage({ id: TabDataMsgType.resetTab, data: "" })
  return;
}

export const segregateLandingSegmentationData = (csvJSonArray: any, analysedData: any, setSeggregatedLandingSegmentData: any) => {
  let segmentData: any = [];
  let timeLineBg: any = [];
  // console.log(`analysedData: `, analysedData)
  analysedData.landingSegData?.sort((a: any, b: any) => a.sequenceId > b.sequenceId);
  // analysedDataToExport = analysedData;
  const graphDataLength = analysedData.graphData?.length;
  // console.log(`graphData.length: `, analysedData.graphData.length);
  analysedData.graphData?.map((element: any) => {
    // let indexJson: string = element.labelName;
    // let additionalColumns: any = additionalColumnsJson[indexJson];
    // let segregatedSegmentData = segregateData(csvJSonArray, additionalColumns || [], element.start, element.end + 1, element.distFromRwy)
    // if (element.sequenceId === graphDataLength - 1) {
      // segregatedSegmentData = segregateData(csvJSonArray, additionalColumns || [], element.start, element.end, element.distFromRwy)
    // }
    // console.log(`element: `, element)

    if (!csvJSonArray[element.end]) {
      element.end = (element.end - 1)
    }

    let segement = {
      label: element.labelName,
      // data: segregatedSegmentData,
      sequenceId: element.sequenceId,
      circuitNo: element.circuitNo,
      duration: calculateDuration(GetJulianDateFromTimeStamp(csvJSonArray[element.start]), GetJulianDateFromTimeStamp(csvJSonArray[element.end])),
      isVisible: true,
      cesiumEntity3D: null,
      //cesiumEntity2D:null,
      labelId: element.labelId,
      // graphList: graphList[element.labelName] || [],
      airportCode: element.airportCode,
      runway: element.runway,
      partitionIndex: element.partitionIndex || null,
      // subPhaseMapping: subPhasemapping(analysedData, element.sequenceId)
      subPhaseMapping: subPhasemapping(analysedData?.graphData, element.labelId, element.sequenceId)
    }
    let phaseDuration:number = 0;
    if (element.sequenceId === 0) {
      phaseDuration = JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start]))
    }else {
      phaseDuration = JulianDate.secondsDifference(GetJulianDateFromTimeStamp(csvJSonArray[element.end]), GetJulianDateFromTimeStamp(csvJSonArray[element.start-1]))
    }

    // console.log(`Phase DUration of ${element.sequenceId} is: `, phaseDuration)
    
    segmentData.push(segement);
        
    if (element.labelId < 8) {
      timeLineBg.push({
        sequenceId: element.sequenceId,
        phaseColor: phaseColor(element.labelName),
        duration: phaseDuration
      })
    }
  })

  // console.log(`airTime: `, airTime)
  //segmentData.sort((a:any,b:any) =>a.sequenceId >b.sequenceId)
  segmentData.sort((a: any, b: any) => parseFloat(a.sequenceId) - parseFloat(b.sequenceId));
  setSeggregatedLandingSegmentData(segmentData);
  return;
}

const subPhasemapping = (graphData: any, labelId: number, sequenceId: number) => {
  let subPhasesSequenceId: any = []
  try {
  // if (labelId && labelId < 8) {
    if (labelId && labelId <= 9) {
      // console.log(`labelId: `, labelId)
      for (let i = sequenceId; i < graphData?.length; i++) {
        let j = i + 1;
        let data = graphData[j];
        if (data?.labelId > 9) {
          subPhasesSequenceId.push(data.sequenceId);
        } else {
          break;
        }
      }
    }
  } catch (error) {
    console.log(`Error in Sub-Phase Mapping: `, error)
  }
  return subPhasesSequenceId
}


const calculateDuration = (firstDate: any, secondDate: any) => {
  let differnceInSec = JulianDate.secondsDifference(secondDate, firstDate)
  return secondsToHms(differnceInSec);
}

/**
 * Converts seconds to HH:MM:SS format.
 * @param {number} d - The duration in seconds.
 * @returns {string} - The duration in HH:MM:SS format.
 */
function secondsToHms(d: any) {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);

  let hDisplay = (h > 9) ? (h + ":") : ("0" + h + ":");
  let mDisplay = (m > 9) ? (m + ":") : ("0" + m + ":");
  let sDisplay = (s > 9) ? (s) : ("0" + s);
  return (hDisplay + mDisplay + sDisplay);
}

/**
 * Returns the filter column name based on the airport and runway filter parameters.
 * @param {Object} airportAndRunwayFilterParam - The airport and runway filter parameters.
 * @returns {string} - The filter column name.
 */
export const airportFilterColumnName = (airportAndRunwayFilterParam: any) => {
  if (airportAndRunwayFilterParam['filterColumn'] === 0) {
    return "Select a column"
  } else if (airportAndRunwayFilterParam['filterColumn'] === 1) {
    return 'Only on "From" column'
  } else if (airportAndRunwayFilterParam['filterColumn'] === 2) {
    return 'Only on "To" column'
  } else {
    return "Both columns"
  }
}


/**
 * Returns the color code associated with a specific flight phase.
 * @param {string} phase - The flight phase.
 * @returns {string} - The color code associated with the given flight phase.
 */
const phaseColor = (phase: string) => {
  // console.log(`Phase: `, phase)
  let phasecolor = "brown"
  const phaseColorObj: any = {
    Taxi: "rgb(200,224,103)",
    Takeoff: "rgb(169,73,204)",
    Landing: "rgb(245,205,113)",
    Approach: "rgb(49,165,245)",
    Cruise: "rgb(10,10,245)",
    Descent: "rgb(138,6,138)",
    Climb: "rgb(7,163,7)",
    Stall: "",
    Low_Ias: "",
    Not_detected: "rgb(0,255,255)", // cyan
    Unknown_Maneuver: "",
    Data_missing: ""
  }
  phasecolor = phaseColorObj[phase] || "brown"
  return phasecolor
}

/**
 * Generates a linear gradient string based on the phase timeline data and flight duration.
 * @param {Array} timeLineBg - The phase timeline data.
 * @param {number} flightDuration - The total flight duration in seconds.
 * @returns {string} - The linear gradient string for the timeline background.
 */
const phasePercentage = (timeLineBg: any, flightDuration: any) => {
  // let totalDur = 0;
  // timeLineBg.map((item:any) => {
  //   totalDur += item.duration; 
  // })
  // console.log(`totalDur timeLineBg: `, totalDur)

  // console.log(`timeLineBg: `, timeLineBg)
  // console.log(`flightDuration: `, flightDuration)
  let lastSequenceId = timeLineBg[timeLineBg.length-1]?.sequenceId;
  let bgStyleString = ""
  let percentage = 0
  let phaseColor = ""
  let startPercentage = 0
  timeLineBg.sort((a: any, b: any) => parseFloat(a.sequenceId) - parseFloat(b.sequenceId)).map((val: any) => {
    phaseColor = val.phaseColor
    // console.log(`phaseColor: `, phaseColor)
    // percentage = Math.round(val.duration / flightDuration * 100) + startPercentage
    percentage = Number(((val.duration / flightDuration * 100) + startPercentage).toFixed(2));
    if (percentage > 100) {
      percentage = 100;
    }
    // if (timeLineBg.length === val.sequenceId + 1) {
    //   bgStyleString += phaseColor + " " + startPercentage + "%" + " " + percentage + "%"
    // } else {
    //   bgStyleString += phaseColor + " " + startPercentage + "%" + " " + percentage + "%" + ", "
    // }
    if (val.sequenceId !== lastSequenceId) {
      bgStyleString += phaseColor + " " + startPercentage + "%" + " " + percentage + "%" + ", "
    } else {
      bgStyleString += phaseColor + " " + startPercentage + "%" + " " + percentage + "%"
    }
    startPercentage = percentage
  })
  // console.log(`bgStyleString: `, bgStyleString)
  return "linear-gradient(to right, " + bgStyleString + " )"
}


/**
 * Enumerates the types of messages exchanged between components in the application.
 */
export enum TabDataMsgType {
  getPfdCsv,              // Request PFD path for tab
  setPfdCsv,              // Set PFD CSV data
  setClockState,          // Set clock state for all tabs
  getClockState,          // Get clock state for all tabs
  getFpdSegregatedData,   // Request flight path tab segregated data
  setFpdSegregatedData,   // Set flight path tab segregated data
  getFpdAnalysisData,     // Request flight path tab analysis data
  setFpdAnalysisData,     // Set flight path tab analysis data
  getFpdCsv,              // Request flight path tab CSV data
  setFpdCsv,              // Set flight path tab CSV data
  setFpdSequenceIdShowHidePhase,  // Set show/hide state for specific sequence ID in flight path tab
  setFpdAllPhasesChecked,  // Set all phases in flight path tab as checked
  setFpdAllPhasesUnChecked,  // Set all phases in flight path tab as unchecked
  getFpdInitPhaseCheckState,  // Request initial phase check state for flight path tab
  setFpdInitPhaseCheckState,  // Set initial phase check state for flight path tab
  closeNewTabs,           // Close new tabs
  setClockSpeed,          // Set clock speed for all tabs
  resetTab,                // Reset tab
  getGraphData,           // Request initial graph data for main graph
  setGraphData,           // Set initial graph data for main graph
  getPhaseGraphData,      // Request initial graph data for phase graph
  setPhaseGraphData,      // Set initial graph data for phase graph
}


export function calculateHeading(lat1: number, lon1: number, lat2: number, lon2: number) {
  // Convert latitude and longitude from degrees to radians
  const toRadians = (angle: number) => angle * (Math.PI / 180);
  lat1 = toRadians(lat1);
  lon1 = toRadians(lon1);
  lat2 = toRadians(lat2);
  lon2 = toRadians(lon2);

  // Calculate differences in coordinates
  const dlat: number = lat2 - lat1;
  const dlon: number = lon2 - lon1;

  // Haversine formula to calculate distance
  const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate initial bearing
  let bearing = Math.atan2(Math.sin(dlon) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dlon));

  // Convert bearing from radians to degrees
  bearing = (bearing + 2 * Math.PI) % (2 * Math.PI);  // Ensure the result is between 0 and 2*pi
  const heading = bearing * (180 / Math.PI);

  return heading;
}

// export const getDateTimeUtcFromEpochTime = (epochTime:any, offset:any) => {
export const getDateTimeUtcFromEpochTime = (epochTime:any) => {
  // console.log(`Type: `, typeof(epochTime));
  // console.log(`epochTime: `, epochTime);
  const date = new Date(Number(epochTime));
  // console.log(`Converted Date: `, date);
  // Format the date and time
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero
  const day = ("0" + date.getDate()).slice(-2); // Add leading zero
  const hours = ("0" + date.getHours()).slice(-2); // Add leading zero
  const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero
  const seconds = ("0" + date.getSeconds()).slice(-2); // Add leading zero

  // console.log(`Formatted String: `, `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`);
  
  // Return the formatted date and time string
  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
}


export enum FileUploadStatus {
  fileUploaded,
  fileUploading,
  fileProcessed,
  fileUploadFailed,
  fileUploadCanceled,
  fileDuplicate
}
