import "../../Import.scss";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import LoadSpinnerBig from "../../../../../../common/components/loadSpinner/loadSpinnerBig";
import axios from "axios";
import { getLocalStorage } from "../../../../../../common/utils/localStorageHandler";
import UploadFolderService from "../../../../../../services/uploadFolderService";
import * as XLSX from 'xlsx';

const UploadFolder = () => {
  const flightReportRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const allFilesToUploadRef = useRef([]);
  const completeFilesDataRef = useRef<any>([]);
  const [uploadStats, setUploadStats] = useState({
    successCount: 0,
    failureCount: 0,
    timeTaken: 0,
  });

  const handleDragOverReport = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
  };

  const [rowCount, setRowCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileUpload = (uploadEvent: any) => {

    const files: any = Array.from(uploadEvent.target.files);

    const validExcelMimeTypes = [
      "application/vnd.ms-excel", // .xls
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
      "application/vnd.ms-excel.sheet.macroEnabled.12", // .xlsm
    ];

    const excelExtensions = ["xls", "xlsx", "xlsm", "xlsb", "xlt", "xltx", "xltm"];

    const excelFile = files.filter((file: any) => {
      const extension = file.name.split('.').at(-1)?.toLowerCase() || '';
      return (
        excelExtensions.includes(extension) ||
        validExcelMimeTypes.includes(file.type)
      );
    });

    if (!excelFile) {
      setErrorMessage("Please upload a valid Excel file.");
      setRowCount(0);
      return;
    }

    setErrorMessage(null);

    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer: any = event.target?.result;
      if (!arrayBuffer) {
        setErrorMessage("Failed to read the file. Please try again.");
        return;
      }

      try {
        // Read the file using SheetJS
        const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });

        // Get the first sheet name
        const sheetName = workbook.SheetNames[0];

        // Get the first sheet
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON to get row count
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        setRowCount(jsonData.length); // Set the row count
      } catch (error) {
        console.error("Error processing file:", error);
        setErrorMessage("An error occurred while processing the Excel file.");
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      setErrorMessage("An error occurred while reading the file.");
    };

    // Read the file as array buffer
    reader.readAsArrayBuffer(excelFile[0]);

    const csvLogFiles = files.filter((file: any) => {
      const extension = file.name?.split('.')?.at(-1)?.toLowerCase() || '';
      return (
        'csv' === extension ||
        file.type === 'text/csv'
      );
    });

    allFilesToUploadRef.current = csvLogFiles.concat(excelFile);

    const authToken = getLocalStorage("authTokens", "");

    // console.log(csvLogFiles.map((item: any) => authToken.userid + "/" + item.webkitRelativePath?.split('/')?.slice(1)?.join('/')));

    const payload = allFilesToUploadRef.current.map((item: any) => item.webkitRelativePath?.split('/')?.slice(1)?.join('/'));
    // const payload = allFilesToUploadRef.current.map((item: any) => authToken.userid + "/" + item.webkitRelativePath?.split('/')?.slice(1)?.join('/'));

    // console.log(payload)

    // sendToBackend(payload);

    sendToBackend({
      FileUrls: payload,
      UserId: authToken.userid,
    });

    // setUploadStats((prev) => ({
    //   ...prev,
    //   failureCount: files.length,
    // }))
  };

  // useEffect(() => {
  //   console.log(`Total Rows in Excel Sheet: `, rowCount);
  // }, [rowCount])

  const sendToBackend = (payload: any) => {
    // console.log(`payload: `, payload);

    // UploadFolderService.generateUploadUrl({payload})
    UploadFolderService.generateUploadUrl(payload)
      .then((response: any) => {
        console.log("Presigned URLs received:", response);
        // setUrlArray(response.url);
        uploadFiles(response.urls, payload);
        // console.log(`Session ID: `, response.sessionId);
      }).catch((error) => {
        console.error("Error getting presigned URLs", error);
      });
  };

  const uploadFiles = (urls: any, payload:any) => {
    console.log(`Inside uploadFiles`);
    console.log(`urls: `, urls);

    let successCount = 0;
    let failureCount = 0;
    const startTime = Date.now();

    const retryUpload: any = (file: any, uploadUrl: any) => {

      console.log(`Inside retryUpload`);
      console.log(`file: `, file);
      console.log(`uploadUrl: `, uploadUrl);

      return axios
        .put(uploadUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((res) => {
          successCount += 1;
          setUploadStats((prev) => ({
            ...prev,
            successCount: successCount,
            failureCount: completeFilesDataRef.current.length - successCount,
          }));
          console.log(`${file.name} successfully uploaded.`);
        })
        .catch((err) => {
          console.warn(`Error uploading ${file.name}, retrying...`);
          return retryUpload(file, uploadUrl);
        });
    };

    console.log(`Before uploadPromises`)
    console.log(`allFilesToUploadRef: `, allFilesToUploadRef);

    const uploadPromises = allFilesToUploadRef?.current?.map((file: any) => {
      const fileName = file.name;
      const uploadUrl = urls[fileName];

      if (uploadUrl) {
        console.log(`Uploading ${fileName} to ${uploadUrl}`);
        return retryUpload(file, uploadUrl);
      }

      return null;
    });

    Promise.all(uploadPromises).then(() => {
      
      UploadFolderService.saveToDb(payload)
      .then((response: any) => {
        console.log("saveToDb API Successfully Called: ", response);
      }).catch((error) => {
        console.error("Error in saveToDb API", error);
      });

      const endTime = Date.now();
      const timeTaken = (endTime - startTime) / 1000;

      setUploadStats({
        successCount,
        failureCount,
        timeTaken,
      });

      console.log(`Upload completed. Time taken: ${timeTaken}s`);
      console.log(`Successful uploads: ${successCount}`);
      console.log(`Failed uploads: ${failureCount}`);
    });
  };

  
  return (
    <>
      {isLoading && (
        <div className="spinnerWrap">
          {" "}
          <LoadSpinnerBig />
        </div>
      )}
      <div className="uploadlogs-wrapper">
        <section className="file-uploader">
          <h3 style={{ marginLeft: "0px" }}>
            FOLDER UPLOADER{" "}
            {/* <a href="">
              ( <i className="icon-info"> </i>Supported Avionics )
            </a> */}
          </h3>
          <label
            htmlFor="flightReport"
            onDragOver={handleDragOverReport}
            onDrop={handleDrop}
            className="file-label-dropzone"
            id="flightReportContainer"
          >
            <div className="upload-block-wrap">
              <p id="reportFileName">
                Drop folder here to upload, or{" "}
                <u className="text-deco">Click here to select folder</u>
              </p>
              <input
                type="file"
                {...({ webkitdirectory: "true", mozdirectory: "true" } as any)}
                onChange={handleFileUpload}
                id="flightReport"
                name="flightReport"
                ref={flightReportRef}
              />
            </div>
          </label>

          {/* <div className="text-center mt-5">
            <span className="d-block">Total Files: {completeFilesDataRef.current.length}</span>
            <span className="d-block">Uploaded: {uploadStats.successCount}</span>
            <span className="d-block">Remaining: {uploadStats.failureCount}</span>
          </div> */}


        </section>
      </div>
    </>
  );
};
export default UploadFolder;
