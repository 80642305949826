import fullScreenWindow from "../../../../../common/utils/fullScreenWindow"
import { useEffect, useState, useRef } from "react"
import { controllCamView, currentTimeData } from "../../../../../common/utils/cesiumDataHandler"

const FlightPath = (props: any) => {

    // console.log(`FlightPath Props: `, props);

    // console.log(`FlightPath viewer3D: `, props.viewer3D);
    // console.log(`FlightPath Camera: `, props.viewer3D.camera);

    const [isTopTracked, setIsTopTracked] = useState(false);
    const [isSideTracked, setIsSideTracked] = useState(false);
    const [isFrontTracked, setIsFrontTracked] = useState(false);
    const [isBackTracked, setIsBackTracked] = useState(false);
    const [headPitchRange, setHeadPitchRange] = useState<any>({ head: 90, pitch: 0, range: 100 });
    const [callBackFn, setcallBackFn] = useState<any>({ callback: undefined });
    const [isInitViewOn, setIsInitViewOn] = useState(false)
    const funcHolder: any = {};
    const [timeChangeData, setTimeChangeData] = useState({ altitude: 0, heading: 0, iASpeed: 0 })
    const flightPathViewRef = useRef<number>(0);

    /**
     * zooms out on flight path
     */
    const zoomOut = () => {
        // console.log(`zoomOut Function`);
        let range = headPitchRange.range;

        if (isInitViewOn) {//If camera is focused whole path 
            props.viewer3D.camera.zoomOut(1000);
        }
        else {
            if (props.trackEntity) {//If flight model track is on
                //range=range-9;
                //range = range +2;
                props.viewer3D.camera.zoomOut(100);
            }
            else {
                range = range + 10;
                if (callBackFn.callback)
                    callBackFn.callback();
                controllCamView(props.viewer3D, headPitchRange.head, headPitchRange.pitch, range, setcallBackFn);

            }
            setHeadPitchRange((headPitchRange: any) => {
                headPitchRange.range = range;
                return headPitchRange;
            })
        }
    }

    /**
     * zooms out on flight path
     */
    const zoomIn = () => {
        // console.log(`zoomIn Function`);
        let range = headPitchRange.range;
        if (isInitViewOn) {//If camera is focused whole path 
            props.viewer3D.camera.zoomIn(1000);
        }
        else {

            if (props.trackEntity) {//If flight model track is on
                // range = range - 2;
                props.viewer3D.camera.zoomIn(100);
            }
            else {
                if (range < 20) {
                    return;
                }

                range = range - 10;
                if (callBackFn.callback) callBackFn.callback();
                controllCamView(props.viewer3D, headPitchRange.head, headPitchRange.pitch, range, setcallBackFn);
            }
            setHeadPitchRange((headPitchRange: any) => {
                headPitchRange.range = range;
                return headPitchRange;
            })
        }
    }

    /**
 * Initializes the view by performing various actions, such as toggling track, unsubscribing from the previously defined onTick function,
 * and tracking the path.
 * @returns {void}
 */
    const initView = () => {
        // console.log(`initView Function`);
        //  console.log("Init Clicked ")
        if (props.trackEntity) {
            props.toggleTrack();
        }

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback) callBackFn.callback();

        // Reset tracking states
        setIsTopTracked(false);
        setIsFrontTracked(false);
        setIsBackTracked(false);
        setIsSideTracked(false);

        // Track the path
        props.trackPath();

        // Set the initialization view state to true
        setIsInitViewOn(true);
    };



    /**
 * Moves the camera to the side view by updating states and controlling the camera view.
 * @returns {void}
 */
    const moveCamToSide = () => {
        // console.log(`moveCamToSide Function`);
        // Reset initialization view state and tracking states
        flightPathViewRef.current = 2;
        setIsInitViewOn(false);
        setIsTopTracked(false);
        setIsFrontTracked(false);
        setIsBackTracked(false);
        setIsSideTracked(true);

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback)
            callBackFn.callback();

        // Control the camera view to the side
        controllCamView(props.viewer3D, 90, 0, headPitchRange.range, setcallBackFn);

        // Update the head-pitch range state
        setHeadPitchRange({ head: 90, pitch: 0, range: headPitchRange.range });

        // Toggle tracking if enabled
        if (props.trackEntity)
            props.toggleTrack();
    };



    /**
 * Moves the camera to the top view by updating states and controlling the camera view.
 * @returns {void}
 */
    const moveCamToTop = () => {
        // console.log(`FlightPath props: `, props);
        // console.log(`moveCamToTop Function`);
        // Reset initialization view state and update tracking states
        flightPathViewRef.current = 1;
        setIsInitViewOn(false);
        setIsTopTracked(true);
        setIsFrontTracked(false);
        setIsBackTracked(false);
        setIsSideTracked(false);

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback) {
            // console.log(`Inside callback IF`)
            callBackFn.callback();
        }

        // Control the camera view to the top
        controllCamView(props.viewer3D, 90, -90, headPitchRange.range, setcallBackFn);

        // Update the head-pitch range state
        setHeadPitchRange({ head: 90, pitch: -90, range: headPitchRange.range });

        // Toggle tracking if enabled
        if (props.trackEntity) {
            // console.log(`Inside trackEntity IF`)
            props.toggleTrack();
        }
    };



    /**
 * Moves the camera to the back view by updating states and controlling the camera view.
 * @returns {void}
 */
    const moveCamToBack = () => {
        // console.log(`moveCamToBack Function`);
        // Reset initialization view state and update tracking states
        flightPathViewRef.current = 3;
        setIsInitViewOn(false);
        setIsTopTracked(false);
        setIsFrontTracked(false);
        setIsBackTracked(true);
        setIsSideTracked(false);

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback)
            callBackFn.callback();

        // Control the camera view to the back
        controllCamView(props.viewer3D, 0, 0, headPitchRange.range, setcallBackFn);

        // Update the head-pitch range state
        setHeadPitchRange({ head: 0, pitch: 0, range: headPitchRange.range });

        // Toggle tracking if enabled
        if (props.trackEntity)
            props.toggleTrack();
    };



    /**
 * Moves the camera to the front view by updating states and controlling the camera view.
 * @returns {void}
 */
    const moveCamToFront = () => {
        // console.log(`moveCamToFront Function`);
        // Reset initialization view state and update tracking states
        flightPathViewRef.current = 0;
        setIsInitViewOn(false);
        setIsTopTracked(false);
        setIsFrontTracked(true);
        setIsBackTracked(false);
        setIsSideTracked(false);

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback)
            callBackFn.callback();

        // Control the camera view to the front
        controllCamView(props.viewer3D, 180, 0, headPitchRange.range, setcallBackFn);

        // Update the head-pitch range state
        setHeadPitchRange({ head: 180, pitch: 0, range: headPitchRange.range });

        // Toggle tracking if enabled
        if (props.trackEntity)
            props.toggleTrack();
    };

    // 0euihw idai ldalda lajdaj

    /**
 * Toggles the tracking of the entity by updating states and calling the toggleTrack function from props.
 * @returns {void}
 */
    const toggleTrack = () => {
        // console.log(`toggleTrack Function FlightPath`);
        // console.log(`FlightPath Props: `, props)
        // Reset initialization view state and tracking states
        setIsInitViewOn(false);
        setIsTopTracked(false);
        setIsFrontTracked(false);
        setIsBackTracked(false);
        setIsSideTracked(false);

        // Toggle tracking using the toggleTrack function from props
        props.toggleTrack();

        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback)
            callBackFn.callback();
    };



    /**
 * useEffect hook to handle component reset by unsubscribing from the onTick function and resetting tracking states.
 */
    useEffect(() => {
        // Unsubscribe from the previously defined onTick function
        if (callBackFn.callback)
            callBackFn.callback();

        // Reset tracking states
        setIsTopTracked(false);
        setIsFrontTracked(false);
        setIsBackTracked(false);
        setIsSideTracked(false);
        setIsInitViewOn(false);
    }, [props.reset]);

    const keyBoardDownEvents = (event: any) => {
        if (event.key === "v" || event.key === "V") {
            if (flightPathViewRef.current === 0) {
                document.getElementById("moveCamToTopButton")?.click();
            } else if (flightPathViewRef.current === 1) {
                document.getElementById("moveCamToSideButton")?.click();
            } else if (flightPathViewRef.current === 2) {
                document.getElementById("moveCamToBackButton")?.click();
            } else if (flightPathViewRef.current === 3) {
                document.getElementById("moveCamToFrontButton")?.click();
            }
        } else if (event.key === "t" || event.key === "T") {
            document.getElementById("toggleTrackButton")?.click();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keyBoardDownEvents);
        // cleanup this component
        return () => {
            window.removeEventListener('keydown', keyBoardDownEvents);
        };
    }, []);


    /**
 * Sets up the component clock by unsubscribing from the previous onTick event and subscribing to a new one to update time change data.
 * @returns {void}
 */
    const compClock = () => {
        // console.log(`compClock Function`);
        // Unsubscribe from the previously defined onTick event
        if (funcHolder.unsubScribeEvent1)
            funcHolder.unsubScribeEvent1();

        // Subscribe to a new onTick event to update time change data
        let event = props.viewer3D.clock.onTick.addEventListener(function (clock: any) {
            setTimeChangeData({ altitude: currentTimeData.altitude, heading: currentTimeData.heading, iASpeed: currentTimeData.iASpeed });
        });

        // Store the new onTick event in the function holder
        funcHolder["unsubScribeEvent1"] = event;
    };


    /**
    * Opens a new tab with the specified URL for the flight path.
    * @returns {void}
    */
    // const newTab = () => {
    //     window.open('/special-tabs/flight-path', '_blank', 'toolbar=no, location=no, status=no');
    // };

    const newTab = () => {
        window.open(
            '/special-tabs/flight-path',
            '_blank',
            'toolbar=no, location=no, status=no, width=800, height=300, top=390, left=0, resizable=yes, scrollbars=yes'
        );
    };


    /**
    * useEffect hook to set up the component clock when the 3D viewer is available.
    */
    useEffect(() => {
        if (props.viewer3D) {
            compClock();
        }
    }, [props.viewer3D]);


    return (
        <>
            <div className="debrief-cards" id="flightPath" style={{overflow: "hidden"}}>
                <div className="debrief-cards-handle">
                    FLIGHT PATH
                    <div className="button-holder d-flex">
                        {/* <button className="btn-customize"></button> */}
                        <button className="btn-maximize" title="Fullscreen" onClick={() => fullScreenWindow("flightPath")}></button>
                        {/* <button className="btn-option"></button> */}
                        <button className="btn-new-tab" title="Open in new tab" onClick={newTab}></button>
                    </div>
                </div>
                <div className="debrief-cards-content overflow-auto" id="">

                    <div className={props.ispointerEventDisable ? 'h-100 w-100 pe-none' : 'h-100 w-100'} id="viewer3dWrap">
                        <div id="viewer3d" className="viewer"> </div>
                    </div>

                    <div className="toggle-flight-path">
                        <label className="switch">
                            <input type="checkbox" defaultChecked={true} onChange={props.toggleShowFlightPath3D} />
                            <span className="slider">
                                <span>2D</span>
                                <span>3D</span>
                            </span>
                        </label>
                        {/* <BootstrapSwitchButton
                        onstyle="primary" offstyle="primary"
                        onChange={()=>setShowFlightPathTab(!showFlightPathTab)}
                        checked={showFlightPathTab}
                        onlabel='3D'
                        offlabel='2D'
                    /> */}
                    </div>

                    {/* NEW CODE for data wrapper */}

                    <div className="flightPath-data-wrapper" style={{ pointerEvents: 'none' }}>
                        <span className="flightPath-dataText">{`${timeChangeData.iASpeed} K IAS`}</span>
                        <div style={{ marginTop: `-4px`}}></div>
                        <span className="flightPath-dataText">{`${timeChangeData.heading} Deg`}</span>
                        <div style={{ marginTop: `-4px`}}></div>
                        <span className="flightPath-dataText">{`${timeChangeData.altitude}' MSL`}</span>
                    </div>

                    {/* NEW CODE END */}

                    <div className="zoom-cntrl-wrapper">
                        <button className="init-view" onClick={initView}></button>
                        <button className="zoom-in" onClick={zoomIn} disabled={headPitchRange.range < 20}></button>
                        <button className="zoom-out" onClick={zoomOut}></button>
                    </div>

                    <div className="controls-flight-path">
                        <button className={props.trackEntity ? "btn-track active" : "btn-track"} id="toggleTrackButton" onClick={toggleTrack}></button>
                        <button className={isTopTracked ? "btn-top-view active" : "btn-top-view"} id="moveCamToTopButton" onClick={moveCamToTop}></button>
                        <button className={isSideTracked ? "btn-side-view active" : "btn-side-view"} id="moveCamToSideButton" onClick={moveCamToSide}></button>
                        <button className={isBackTracked ? "btn-chase-view active" : "btn-chase-view "} id="moveCamToBackButton" onClick={moveCamToBack}></button>
                        <button className={isFrontTracked ? "btn-front-view active" : "btn-front-view"} id="moveCamToFrontButton" onClick={moveCamToFront}></button>
                        <span className="separator"></span>
                        <label className="speed"><span>{timeChangeData.iASpeed}</span>kts</label>
                        <label className="heading"><span>{timeChangeData.heading}</span>º</label>
                        <label className="altitude"><span>{timeChangeData.altitude}</span>'</label>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FlightPath;