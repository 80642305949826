import { useEffect, useState, useRef } from "react"
import "./specialTabs.scss"
import { PlotWallNPolyline, addTrackEntity, AddVariablesToSampleProperty, currentTimeData, clockViewModel, controllCamView, GetJulianDateFromTimeStamp } from "../../../../common/utils/cesiumDataHandler"
import { TabDataBrodacast, TabDataMsgType, segregateGraphData, TabTimeSyncBroadcast } from "../../../../common/utils/utils"
import { LoadTerrain, InitViewer1 } from "../../../../common/utils/cesiumInit"
import { Cartographic, ClockRange, ClockStep, JulianDate, SceneMode } from "cesium"
import CesiumFlightPath3D from "../cesiumFlightPath/CesiumFlightPath3D"

const TabFlightPath = () => {

  const flightPathViewRef = useRef<number>(0);

  let TabTimeSyncBroadcastEvent = null;
  let TabDataBrodacastEvent = null;

  const subScribTimeData = () => {
    TabTimeSyncBroadcastEvent = TabTimeSyncBroadcast.onmessage = (time) => {
      try {
        // if (Math.abs(JulianDate.secondsDifference(clockViewModel.currentTime, time)) > 0.001) {
        //   clockViewModel.currentTime = time;
        // }
        if (JulianDate.secondsDifference(clockViewModel.currentTime, time) > 1 || JulianDate.secondsDifference(clockViewModel.currentTime, time) < -1) {
          clockViewModel.currentTime = time
        }
        //clockViewModel.currentTime = time
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  }

  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false);
  const [segregatedData, setSegregatedData] = useState([])
  const [analysisData, setAnalysisData] = useState<any>(null)
  const [viewer3D, setViewer3D] = useState<any>()
  const [viewer2D, setViewer2D] = useState(false)
  const [isViewerLoaded, setIsViewerLoaded] = useState(false)
  const [initPhaseState, setInitPhaseState] = useState([])


  // From flight path comp
  const [isTopTacked, setIsTopTracked] = useState(false);
  const [isSideTracked, setIsSideTracked] = useState(false);
  const [isFrontTracked, setIsFrontTracked] = useState(false);
  const [isBackTracked, setIsBackTracked] = useState(false);
  const [headPitchRange, setHeadPitchRange] = useState<any>({ head: 90, pitch: 0, range: 100 });
  const [callBackFn, setcallBackFn] = useState<any>({ callback: undefined });
  const [isInitViewOn, setIsInitViewOn] = useState(false)
  const funcHolder: any = {};
  const [timeChangeData, setTimeChangeData] = useState({ altitude: 0, heading: 0, iASpeed: 0 })
  const [showFlightPath3D, setShowFlightPath3D] = useState(true)
  const [trackEntity, setTrackEntity] = useState(true)
  const [clockState, setClockState] = useState<boolean>();


  const subScribEventData = () => {
    TabDataBrodacastEvent = TabDataBrodacast.onmessage = (msg) => {
      //  console.log("tab data called ");
       console.log(msg)
       console.log(`TabDataMsgType.setFpdAnalysisData: `, TabDataMsgType.setFpdAnalysisData);

      if (msg.id === TabDataMsgType.setFpdAnalysisData) {
        setAnalysisData(msg.data)
        //console.log(msg.data)
      }
      if (msg.id === TabDataMsgType.setFpdCsv) {
        // console.log(`tab csv: `, msg.data)
        setData(msg.data)
      }
      if (msg.id === TabDataMsgType.setClockState) {
        //  setData(msg.data)
        setClockState(msg.data);
        clockViewModel.canAnimate = msg.data
        clockViewModel.shouldAnimate = msg.data
      }
      if (msg.id === TabDataMsgType.setFpdSequenceIdShowHidePhase) {
        ChangePhaseVisibility(msg.data.sequenceId, msg.data.visibility, changeVisibility)
      }
      if (msg.id === TabDataMsgType.setFpdAllPhasesChecked) {
        checkAllPhases(changeVisibility)
      }
      if (msg.id === TabDataMsgType.setFpdAllPhasesUnChecked) {
        unCheckAllPhases(changeVisibility)
      }
      if (msg.id === TabDataMsgType.setFpdInitPhaseCheckState) {
        setInitPhaseState(msg.data)
      }
      if (msg.id === TabDataMsgType.closeNewTabs) {
        window.close()
      }
      if (msg.id === TabDataMsgType.setClockSpeed) {
        clockViewModel.multiplier = msg.data
      }
      if (msg.id === TabDataMsgType.resetTab) {
        resetTab()
      }
    }
  }

  useEffect(() => {
    TabDataBrodacast.postMessage({ id: TabDataMsgType.getClockState, data: "" })
  }, [])

  const initView = () => {
    if (trackEntity) {
      toggleTrackMain();
    }

    if (callBackFn.callback) callBackFn.callback()
    setIsTopTracked(false);
    setIsFrontTracked(false);
    setIsBackTracked(false);
    setIsSideTracked(false);
    trackPath();
    setIsInitViewOn(true)
  }


  const zoomOut = () => {
    let range = headPitchRange.range;

    if (isInitViewOn) {
      viewer3D.camera.zoomOut(1000);
    }
    else {
      if (trackEntity) {
        //range=range-9;
        //range = range +2;
        viewer3D.camera.zoomOut(100);
      }
      else {
        range = range + 10;
        if (callBackFn.callback)
          callBackFn.callback();
        controllCamView(viewer3D, headPitchRange.head, headPitchRange.pitch, range, setcallBackFn);

      }
      setHeadPitchRange((headPitchRange: any) => {
        headPitchRange.range = range;
        return headPitchRange;
      })
    }
  }

  const zoomIn = () => {
    let range = headPitchRange.range;
    if (isInitViewOn) {
      viewer3D.camera.zoomIn(1000);
    }
    else {

      if (trackEntity) {
        // range = range - 2;
        viewer3D.camera.zoomIn(100);
      }
      else {
        if (range < 20) {
          return;
        }

        range = range - 10;
        if (callBackFn.callback) callBackFn.callback();
        controllCamView(viewer3D, headPitchRange.head, headPitchRange.pitch, range, setcallBackFn);
      }
      setHeadPitchRange((headPitchRange: any) => {
        headPitchRange.range = range;
        return headPitchRange;
      })
    }
  }

  const keyBoardDownEvents = (event: any) => {
    if (event.key === "v" || event.key === "V") {
        if (flightPathViewRef.current === 0) {
            document.getElementById("moveCamToTopButton")?.click();
        } else if (flightPathViewRef.current === 1) {
            document.getElementById("moveCamToSideButton")?.click();
        } else if (flightPathViewRef.current === 2) {
            document.getElementById("moveCamToBackButton")?.click();
        } else if (flightPathViewRef.current === 3) {
            document.getElementById("moveCamToFrontButton")?.click();
        }
    } else if (event.key === "t" || event.key === "T") {
        document.getElementById("toggleTrackButton")?.click();
    }
}

  useEffect(() => {
    window.addEventListener('keydown', keyBoardDownEvents);
    // cleanup this component
    return () => {
      window.removeEventListener('keydown', keyBoardDownEvents);
    };
  }, []);


  const toggleTrack = () => {
    setIsInitViewOn(false)
    setIsTopTracked(false);
    setIsFrontTracked(false);
    setIsBackTracked(false);
    setIsSideTracked(false);

    // if(props.trackEntity)
    toggleTrackMain();
    if (callBackFn.callback) callBackFn.callback()
  }

  const moveCamToTop = () => {
    flightPathViewRef.current = 1;
    setIsInitViewOn(false)
    setIsTopTracked(true);
    setIsFrontTracked(false);
    setIsBackTracked(false);
    setIsSideTracked(false);
    if (callBackFn.callback) callBackFn.callback();
    controllCamView(viewer3D, 90, -90, headPitchRange.range, setcallBackFn);
    setHeadPitchRange({ head: 90, pitch: -90, range: headPitchRange.range })
    if (trackEntity)
      toggleTrack();
  }

  const moveCamToSide = () => {
    flightPathViewRef.current = 2;
    setIsInitViewOn(false)
    setIsTopTracked(false);
    setIsFrontTracked(false);
    setIsBackTracked(false);
    setIsSideTracked(true);
    if (callBackFn.callback)
      callBackFn.callback();
    controllCamView(viewer3D, 90, 0, headPitchRange.range, setcallBackFn);
    setHeadPitchRange({ head: 90, pitch: 0, range: headPitchRange.range })
    if (trackEntity)
      toggleTrack();
  }

  const moveCamToBack = () => {
    flightPathViewRef.current = 3;
    setIsInitViewOn(false)
    setIsTopTracked(false);
    setIsFrontTracked(false);
    setIsBackTracked(true);
    setIsSideTracked(false);
    if (callBackFn.callback) callBackFn.callback();
    controllCamView(viewer3D, 0, 0, headPitchRange.range, setcallBackFn);
    setHeadPitchRange({ head: 0, pitch: 0, range: headPitchRange.range })
    if (trackEntity)
      toggleTrack();
  }
  const moveCamToFront = () => {
    flightPathViewRef.current = 0;
    setIsInitViewOn(false)
    setIsTopTracked(false);
    setIsFrontTracked(true);
    setIsBackTracked(false);
    setIsSideTracked(false);
    if (callBackFn.callback) callBackFn.callback();
    controllCamView(viewer3D, 180, 0, headPitchRange.range, setcallBackFn);
    setHeadPitchRange({ head: 180, pitch: 0, range: headPitchRange.range })
    if (trackEntity)
      toggleTrack();
  }

  const trackPath = () => {
    let allEntity: any = [];

    try {
      segregatedData.map((e: any) => {
        if (e.labelId < 10) {
          allEntity.push(...e.cesiumEntity3D);
        }
      })
      //  console.log(allEntity);
      viewer3D.flyTo(allEntity);
    } catch(error) {
      console.log(`error: `, error);
    }
  }

  const changeSceneMode = () => {
    if (showFlightPath3D) {
      viewer3D.scene.mode = SceneMode.SCENE2D
      if (trackEntity) viewer3D.trackedEntity = viewer2D;
      setShowFlightPath3D(false)
    } else {
      viewer3D.scene.mode = SceneMode.SCENE3D;
      if (trackEntity) viewer3D.trackedEntity = viewer2D;
      setShowFlightPath3D(true)
    }
  }

  const toggleTrackMain = () => {
    //console.log("Called ")
    // console.log(props.viewer3D.trackedEntity )
    if (viewer3D.trackedEntity) {
      // console.log("Untracked ")
      viewer3D.trackedEntity = null;

      //console.log(props.viewer3D.trackedEntity )
      setTrackEntity(false)
    } else {
      // console.log("Tracked ")
      viewer3D.trackedEntity = viewer2D

      setTimeout(() => {
        viewer3D.camera.zoomOut(50);
        // clearInterval(clockInt1); 
      }, 1)
      setTrackEntity(true)
    }

  }

  const compClock = () => {
    if (funcHolder.unsubScribeEvent1) funcHolder.unsubScribeEvent1();
    let event = viewer3D.clock.onTick.addEventListener(function (clock: any) {
      setTimeChangeData({ altitude: currentTimeData.altitude, heading: currentTimeData.heading, iASpeed: currentTimeData.iASpeed })
    })
    funcHolder["unsubScribeEvent1"] = event;
  }

  const updateHeightAndPlotWall = (response: any) => {

    segregateGraphData(data, response, setSegregatedData, setAnalysisData).then(async (): Promise<any> => {
      let isStateChanged = false
      let entityArray: any = [];
      setViewer3D((viewer3D: any) => {

        if (!isStateChanged) {
          let isInnerStateChanged = false
          setSegregatedData((segregatedData) => {

            if (!isInnerStateChanged) {
              viewer3D?.entities.removeAll()
              segregatedData.map((e: any) => {
                let cordArray: any = []
                e.data.forEach(function (item: any, index: any, arr: any) {
                  cordArray.push(+item.Longitude);
                  cordArray.push(+item.Latitude);
                  cordArray.push(+item.AltGPS);
                })
                if (e.partitionIndex === null) { // added later
                  let segWall = PlotWallNPolyline(viewer3D, cordArray, e.label);
                  //entityArr.push(segWall[0]);
                  entityArray.push(segWall[0])
                  e.cesiumEntity3D = segWall;
                }
              })
              //viewer3D.flyTo(entityArray);
              addTrackEntity(viewer3D).then((entity: any) => {
                setViewer2D(entity)
                setIsLoading(false);
                setTimeout(() => {
                  //console.log("Ticked ")
                  viewer3D.trackedEntity = entity;
                  initPhaseVisibility(initPhaseState, segregatedData, changeVisibility)
                  //viewer3D.flyTo(entityArray);
                }, 2000)

              });
              isInnerStateChanged = true;
            }
            return segregatedData
          })
          isStateChanged = true;
        }

        return viewer3D;
      })
    })


  }

  const initPhaseVisibility = (sequenceIds: Array<number>, segregatedData: any, callback: Function) => {
    segregatedData.filter((data: any, index: any) => sequenceIds.includes(data.sequenceId)).map((value: any) => {
      value.isVisible = false
    })
    callback()
  }

  const InitViewer = async (): Promise<any> => {
    let viewer2
    if (!viewer3D) {
      let terrainProvider = await LoadTerrain();
      if (terrainProvider) {
        //console.log("got terrainProvider")
        viewer2 = InitViewer1(terrainProvider);
        setViewer3D(viewer2);
      } else {
        //console.log("not got terrainProvider")
      }

    } else {
      viewer2 = viewer3D;
    }
    return viewer2
  }



  useEffect(() => {
    subScribEventData()
    subScribTimeData()

    initPhaseVisibility(initPhaseState, segregatedData, changeVisibility)

    if (!data) {
      console.log("csv request")
      console.log(``)
      TabDataBrodacast.postMessage({ id: TabDataMsgType.getFpdCsv, data: "" })
    }

    if (!analysisData) {
      console.log("analyzedData request")
      TabDataBrodacast.postMessage({ id: TabDataMsgType.getFpdAnalysisData, data: "" })
      TabDataBrodacast.postMessage({ id: TabDataMsgType.getFpdInitPhaseCheckState, data: "" })

      //added later
      // TabDataBrodacast.postMessage({id:TabDataMsgType.getFpdCsv, data:""})
    }

    if (!isViewerLoaded && data && analysisData) {
      setIsViewerLoaded(true)
      //console.log("enter a viwer")

      //added later
      // TabDataBrodacast.postMessage({id:TabDataMsgType.getFpdCsv, data:""})            

      InitViewer().then((viewer) => {
        let interval: any;
        interval = setInterval(() => {
          if (data !== null) {
            let pointArr = []
            if (data !== null) {
              for (let index = 0; index < data.length; index++) {
                pointArr.push(Cartographic.fromRadians(
                  +data[index].Longitude,
                  +data[index].Latitude,
                )
                );
              };
            }


            AddVariablesToSampleProperty(data);
            //console.log(viewer)
            SetClock(data, viewer)
            updateHeightAndPlotWall(analysisData)
            clearInterval(interval)
          }
        }, 200)

      }).catch(() => { })
    }
  }, [data, analysisData]);

  function SetClock(csvJSonArray: any, viewer: any) {

    const element = csvJSonArray[0] || csvJSonArray[1] || csvJSonArray[2];
    //const startTime = GetJulianDate(element);
    const startTime = GetJulianDateFromTimeStamp(element);

    const lastElement = csvJSonArray[csvJSonArray.length - 1] || csvJSonArray[csvJSonArray.length - 10] || csvJSonArray[csvJSonArray.length - 20];
    // const endTime = GetJulianDate(lastElement)
    const endTime = GetJulianDateFromTimeStamp(lastElement)


    viewer.clock.startTime = startTime.clone();
    viewer.clock.stopTime = endTime.clone();
    viewer.clock.currentTime = startTime.clone();
    viewer.timeline.zoomTo(startTime, endTime);
    viewer.clock.multiplier = 1;
    viewer.clock.shouldAnimate = clockState;
    viewer.clock.clockRange = ClockRange.LOOP_STOP // loop when we hit the end time
    viewer.clock.clockStep = ClockStep.SYSTEM_CLOCK_MULTIPLIER
    viewer.clock.canAnimate = clockState;
  }

  useEffect(() => {
    if (viewer3D) {
      compClock()
    }
  }, [viewer3D])


  const ChangePhaseVisibility = (seqId: number, visibility: boolean, changeVisibilityCallback: Function) => {
    segregatedData.filter((val: any, index: any) => val.sequenceId === seqId).map((val: any) => {
      val.isVisible = visibility;
      //console.log("visibility changed")
    })
    //console.log("visibility changed " +seqId)
    changeVisibilityCallback()
  }

  const changeVisibility = () => {
    segregatedData.map((e: any) => {
      if (e.partitionIndex === null) { // added later
        e.cesiumEntity3D.map((entity: any) => {
          entity.show = e.isVisible;
        })
      }
    })
    //setRenderAgain(!renderAgain);
  }

  const checkAllPhases = (changeVisibilityCallback: Function) => {
    segregatedData.filter((val: any, index: any) => val.isVisible === false).map((val: any) => {
      val.isVisible = true
    })
    changeVisibilityCallback()
  }

  const unCheckAllPhases = (changeVisibilityCallback: Function) => {
    segregatedData.filter((val: any, index: any) => val.isVisible === true).map((val: any) => {
      val.isVisible = false
    })
    changeVisibilityCallback()
  }

  const resetTab = () => {
    setTimeout(() => {
      setData(null)
      setAnalysisData(null)
      setInitPhaseState([])
      setIsViewerLoaded(false)
    }, 500)
  }

  return (
    <div className="spl-tab">
      <div className='tab-parent' id="pfdLayerarent">
        <CesiumFlightPath3D setViewer3D={setViewer3D} ispointerEventDisable={false} />

        <div className="toggle-flight-path">
          <label className="switch">
            <input type="checkbox" defaultChecked={true} onChange={changeSceneMode} />
            <span className="slider">
              <span>2D</span>
              <span>3D</span>
            </span>
          </label>
        </div>

        {/* NEW CODE */}

      <div className="flightPath-data-wrapper" style={{ pointerEvents: 'none' }}>
          <span className="flightPath-dataText">{`${timeChangeData.iASpeed} K IAS`}</span>
          <div style={{ marginTop: `-4px`}}></div>
          <span className="flightPath-dataText">{`${timeChangeData.heading} Deg`}</span>
          <div style={{ marginTop: `-4px`}}></div>
          <span className="flightPath-dataText">{`${timeChangeData.altitude}' MSL`}</span>
      </div>

        {/* NEW CODE END */}

        <div className="zoom-cntrl-wrapper">
          <button className="init-view" onClick={initView}></button>
          <button className="zoom-in" onClick={zoomIn}></button>
          <button className="zoom-out" onClick={zoomOut}></button>
        </div>

        <div className="controls-flight-path">
          <button className={trackEntity ? "btn-track active" : "btn-track"} id="toggleTrackButton" onClick={toggleTrack}></button>
          <button className={isTopTacked ? "btn-top-view active" : "btn-top-view"} id="moveCamToTopButton" onClick={moveCamToTop}></button>
          <button className={isSideTracked ? "btn-side-view active" : "btn-side-view"} id="moveCamToSideButton" onClick={moveCamToSide}></button>
          <button className={isBackTracked ? "btn-chase-view active" : "btn-chase-view "} id="moveCamToBackButton" onClick={moveCamToBack}></button>
          <button className={isFrontTracked ? "btn-front-view active" : "btn-front-view"} id="moveCamToFrontButton" onClick={moveCamToFront}></button>
          <span className="separator"></span>
          <label className="speed"><span>{timeChangeData.iASpeed}</span>kts</label>
          <label className="heading"><span>{timeChangeData.heading}</span>º</label>
          <label className="altitude"><span>{timeChangeData.altitude}</span>'</label>
        </div>
      </div>
    </div>
  )
}

export default TabFlightPath