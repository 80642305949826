import "../../Import.scss";
import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import StickyTable from "../../../../../../common/components/StickyTable/StickyTable";
import {
  useBlockLayout,
  useAbsoluteLayout,
  useTable,
  useSortBy,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  useFlexLayout,
  useRowSelect,
  usePagination,
} from "react-table";
import { useSticky } from "react-table-sticky";
import ColumnSettings from "../../../../../../common/components/ColumnSettings/ColumnSettings";
import getFileExtension, {
  FileUploadStatus,
} from "../../../../../../common/utils/utils";
import UpdateFileInfoModal from "./UpdateFileInfoModal";
import DebriefService from "../../../../../../services/debrief.service";
import PilotService from "../../../../../../services/pilot.service";
import AircraftService from "../../../../../../services/aircraft.service";
import DeleteLogsModal from "./DeleteLogsModal";
import BulkAssignMismatchAlert from "./BulkAssignMismatchAlert";
import LoadSpinnerBig from "../../../../../../common/components/loadSpinner/loadSpinnerBig";
import CommonService from "../../../../../../services/common.service";
import ProgressBar from "../../../../../../common/components/progressBar/progressBar";
import axios from "axios";
import ProgressBar1 from "../../../../../../common/components/progressBar/progressBar1";
import successICon from "../../../../../../assets/images//authentication/icon-success.svg";

const UploadLogs = () => {
  const flightReportRef = useRef<HTMLInputElement>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [SearchField, setSearchField] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [assignModalShow, setAssignModalShow] = React.useState(false);
  const [pilotTypeListValue, setPilotTypeListValue] = useState([]);
  const [pilotListValue, setPilotListValue] = useState([]);
  const [instructorListValue, setInstructorListValue] = useState([]);
  const [aircraftListValue, setAircraftListValue] = useState([]);
  const [exerciseListValue, setExerciseListValue] = useState([]);
  const [airportList, setAirportList] = useState([]);
  const [fileDetails, SetFileDetails] = useState({});
  const [indexNumber, setIndexNumber] = useState(0); //Index number at which next file is added

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [fileId, setFileId] = useState("");
  const [rowIndex, setRowIndex] = useState<any>(); //Index number which is to be deleted from file
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessVisible, setIsSucessVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isMultipleFilesSelected, setIsMultipleFilesSelected] =
    useState<boolean>(false);
  // const [isUpdateVisible, setIsUpdateVisible] = useState(false);

  const [bulkAssignMismatchAlert, setBulkAssignMismatchAlert] = useState(false);

  const TextSearchFilter = (value: any) => {
    setSearchField(value);
  };

  const handleDragOverReport = (event: any) => {
    event.preventDefault();
  };

  type fileInfo = {
    check_box: String;
    file_name: String; //ReactElement<any, any>,
    departure_date_time: String;
    nearest_airport: String;
    registration_no: String;
    pilot_incharge: String;
    status: ReactElement<any, any>;
    id: string;
    lat: string;
    long: string;
    otherDetails: any;
  };

  //to update the table data with the uploaded file details
  const updateFilelistData = (
    file: fileInfo,
    fileUploadStatus: FileUploadStatus,
    fileId: string,
    index: any,
    otherDetails: any
  ) => {
    const newData = addFilesToTable(
      file,
      fileUploadStatus,
      fileId,
      otherDetails
    );
    setData((data) => [
      ...data.slice(0, index),
      newData,
      ...data.slice(index + 1),
    ]);
  };

  const updateDetails = (updateFileInfo: any) => {
    setIsLoading(true);
    let dataArry: any = [];
    dataArry = data.slice();
    let file: any;
    let indexNum: any;
    let pos: any = {};
    for (let index = 0; index < dataArry.length; index++) {
      const element = dataArry[index];
      if (element.id === updateFileInfo.rowid) {
        //new code
        // console.log(`Element: `, element);
        //new code
        file = element;
        indexNum = index;
        file["name"] = element.file_name;
        pos["latitude"] = element.lat;
        pos["longitude"] = element.long;
        pos["depDate"] = element.departure_date_time;
        pos["pilot"] = updateFileInfo.pilotName;
        pos["regNo"] = updateFileInfo.aircrafTail;
        pos["airport"] = updateFileInfo.airportcode;
        pos["isUpdated"] = true;
      }
    }

    // console.log(`updateFileInfo: `, updateFileInfo);
    // console.log(`updateFileInfo.rowid: `, updateFileInfo.rowid);

    DebriefService.updateFileDetails(updateFileInfo)
      .then((res) => {
        // console.log(res);
        updateFilelistData(
          file,
          FileUploadStatus.fileProcessed,
          file.Id,
          indexNum,
          pos
        );
        setIsLoading(false);
        setIsSucessVisible(true);

        let clockInt = setInterval(() => {
          setIsSucessVisible(false);
          clearInterval(clockInt);
        }, 4000);
        let clockInt1 = setInterval(() => {
          setFilter("file_name", searchText);
          clearInterval(clockInt1);
        }, 1);
        DebriefService.sendToRabbitMQ(updateFileInfo.rowid)
          .then((response) => {
            console.log(`Successfully pushed to Rabbit MQ`);
            console.log(response);
          })
          .catch((error) => {
            console.log("Error: Failed to push to Rabit MQ");
            console.log(error);
            // console.log(error);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(`Error updating file details`);
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => { });
  };

  const deleteFileFromList = (index: any) => {
    setData((data) => [...data.slice(0, index), ...data.slice(index + 1)]);
  };

  useEffect(() => {
    // console.log(`data: `, data);
    let i = 0;
    data.forEach((element: any) => {
      element.otherDetails.index = i;
      i++;
    });
  }, [data]);

  const showDeleteModal = (id: string) => {
    setFileId(id);
    setIsMultipleFilesSelected(false);
    setDeleteModalShow(true);
  };

  const deleteFile = async (id: string, index: number) => {
    if (id === "0") {
      deleteFileFromList(+index);
      setSelectedRowIds([]);
      return;
    }
    setIsLoading(true);
    setIndexNumber(data.length);
    try {
      if (id && id !== "0") {
        await DebriefService.deleteFileDetails(id);
      }
      setIsLoading(false);
      deleteFileFromList(+index);
      setIsDeleteVisible(true);
      let clockInt = setInterval(() => {
        setIsDeleteVisible(false);
        clearInterval(clockInt);
      }, 15000);
      let clockInt1 = setInterval(() => {
        setFilter("file_name", searchText);
        clearInterval(clockInt1);
      }, 1);
      setSelectedRowIds([]);
    } catch (error: any) {
      // console.log(`Inside Error`)
      // if (error?.err?.response?.code === -5064) {
      // console.log(`Inside Error IF Statement`)
      deleteFileFromList(+index);
      setIsDeleteVisible(true);
      setSelectedRowIds([]);
      // }
      console.log(error);
      setIsLoading(false);
    }
  };

  const changeData = (id: string, file_name: string, pos: any) => {
    // console.log(`changeData`)
    setIsMultipleFilesSelected(false);
    let filedata = {
      Id: id,
      fileName: file_name,
      pos: pos,
    };
    let lat: Number = parseFloat(pos.latitude);
    let long: Number = parseFloat(pos.longitude);
    setIsLoading(true);
    DebriefService.getAirportList(lat, long)
      .then((res) => {
        // console.log(`AL 1: `, res)
        setIsLoading(false);
        setAirportList(res);
        SetFileDetails(filedata);
        setAssignModalShow(true);
      }).catch((err) => {
        setIsLoading(false);
        console.log(`Error getting airport list: `, err);
      });
  };

  const handleDropReport = async (event: any) => {
    // console.log(`Inside handleDropReport`)
    event.preventDefault();
    let files = event.dataTransfer.files;
    let index = indexNumber;
    let indexPrev = indexNumber;
    for (const file of files) {
      if (getFileExtension(file.name) === "csv") {
        const source = axios.CancelToken.source();
        const newData = addFilesToTable(
          file,
          FileUploadStatus.fileUploading,
          "0",
          { axiosSource: source, index: index, isApiCalled: true, file: file }
        );
        // console.log(`handleDropReport newData: `, newData);
        setData((data) => [...data, newData]);
        index++;
      }
    }
    setIndexNumber(index);
    // console.log(`index: `, index)
    uploadAllFiles(indexPrev);
    // console.log(`indexPrev: `, indexPrev)
  };

  const reportFileLoad = async (event: any) => {
    // console.log(`Inside reportFileLoad`)
    // console.log(`reportFileLoad Event: `, event)
    event.preventDefault();
    let files = event.target.files;
    // console.log(`reportFileLoad files: `, files);
    let index = indexNumber;
    let indexPrev = indexNumber - 1;
    //All uploaded files come here and are sent to table from here
    for (const file of files) {
      if (getFileExtension(file.name) === "csv") {
        const source = axios.CancelToken.source();
        // console.log(`File Load Axios Source: `, source)
        const newData = addFilesToTable(
          file,
          FileUploadStatus.fileUploading,
          "0",
          { axiosSource: source, index: index, isApiCalled: true, file: file }
        );
        setData((data) => [...data, newData]);
        index++;
      }
    }

    setTimeout(() => {
      setIndexNumber(index);
      uploadAllFiles(indexPrev);
    }, 9999);

    // setIndexNumber(index);
    // uploadAllFiles(indexPrev);
  };

  const uploadAllFiles = (prevIndex: Number) => {
    // console.log(`Inside uploadAllFiles`)
    let isStateCalled = false;
    setData((data) => {
      if (!isStateCalled) {
        isStateCalled = true;
        data.forEach(async (e: any, index: Number) => {
          //
          // const newData = addFilesToTable(e.otherDetails.file, 2, "0", { axiosSource: e.otherDetails.axiosSource, index: index, isApiCalled: true, file: e.otherDetails.file });
          if (index > prevIndex) {
            // console.log(index)
            let otherDetails = e.otherDetails;
            otherDetails.isApiCalled = true;
            try {
              // API to be called for retrying failed and re-uploading cancelled files
              let res: any = await DebriefService.uploadDebriefFile(
                e.otherDetails.file,
                e.otherDetails
              );
              // console.log(`uploadAllFiles res: `, res);
              // console.log("Response")
              // console.log(`Upload Res: `, res)
              if (res.code === 6069) {
                //If file is duplicate
                // updateFilelistData(e.otherDetails.file, FileUploadStatus.fileDuplicate, res.id, e.otherDetails.index, otherDetails)
                updateFilelistData(
                  e.otherDetails.file,
                  FileUploadStatus.fileDuplicate,
                  "0",
                  e.otherDetails.index,
                  otherDetails
                );
              } else {
                const d = new Date(res.departureTime);
                let str: any = d.toString().split(" ");
                let year: any = str[3].split("");
                let date =
                  str[2] +
                  " " +
                  str[1] +
                  " " +
                  year[year.length - 2] +
                  year[year.length - 1] +
                  ", " +
                  str[4] +
                  " IST";

                otherDetails["latitude"] = res.latitude.trim();
                otherDetails["longitude"] = res.longitude.trim();
                otherDetails["depDate"] = date;
                //console.log(res)
                updateFilelistData(
                  e.otherDetails.file,
                  FileUploadStatus.fileUploaded,
                  res.id,
                  e.otherDetails.index,
                  otherDetails
                );
              }
            } catch (err: any) {
              //console.log("Index "+ e.otherDetails.index)
              console.log(err);
              if (err?.err?.code === "ERR_CANCELED") {
                //IF uploading is cancled
                // console.log(err);
                updateFilelistData(
                  e.otherDetails.file,
                  FileUploadStatus.fileUploadCanceled,
                  "0",
                  e.otherDetails.index,
                  otherDetails
                );
              } else {
                updateFilelistData(
                  e.otherDetails.file,
                  FileUploadStatus.fileUploadFailed,
                  "0",
                  e.otherDetails.index,
                  otherDetails
                );
              }
            }
            return e;
          }
        });
      }
      return data;
    });
  };
  const abortApi = (source: any) => {
    source.cancel("Cancled ");
  };

  const onSearchInputChange = (e: any) => {
    // console.log("Inputs changed")
    setSearchText(e.target.value);
    setFilter("file_name", e.target.value);
  };

  // const [failedFiles, setFailedFiles] = useState<any[]>([]);
  // const [canceledFiles, setCanceledFiles] = useState<any[]>([]);

  // to add the uploaded files to table
  const addFilesToTable = (
    file: any,
    fileUploadStatus: FileUploadStatus,
    Id: string,
    details: any
  ) => {
    //new code
    // console.log(`addFilesToTable details: `, details);
    //new code
    const newFileInfo: fileInfo = {
      check_box: file.name,
      file_name: file.name, //<div className="text-wrap"> {file.name}</div>,
      departure_date_time: details.depDate || "-",
      nearest_airport: details.airport || "-",
      registration_no: details.regNo || "-",
      pilot_incharge: details.pilot || "-",
      status: (
        <div>
          {(() => {
            if (fileUploadStatus === FileUploadStatus.fileUploaded) {
              return (
                <div className="container d-flex justify-content-space-between">
                  <div className="col">
                    <div className="uploaded-icon">
                      <span className="uploaded-text">Uploaded </span>
                    </div>
                  </div>
                  <div
                    className="col d-flex justify-content-end"
                    style={{ zIndex: 2 }}
                  >
                    <Button
                      style={{ fontSize: "14px" }}
                      onClick={() => {
                        changeData(Id, file.name, details);
                      }}
                    >
                      Assign
                    </Button>
                    <button
                      className="deleteBtn"
                      onClick={() => {
                        showDeleteModal(Id);
                      }}
                    ></button>
                  </div>
                </div>
              );
            } else if (fileUploadStatus === FileUploadStatus.fileUploading) {
              return (
                <div className="d-inline-flex">
                  <div>
                    {details.isApiCalled ? <ProgressBar /> : <ProgressBar1 />}
                  </div>
                  <span className="uploading-text">Uploading..</span>
                  <button
                    className="remove-btn"
                    onClick={() => {
                      abortApi(details.axiosSource);
                    }}
                  ></button>
                </div>
              );
            } else if (fileUploadStatus === FileUploadStatus.fileProcessed) {
              return (
                <div>
                  <div>
                    <div className="uploaded-icon">
                      <span className="uploaded-text">Processed </span>
                    </div>
                  </div>
                </div>
              );
            } else if (fileUploadStatus === FileUploadStatus.fileUploadFailed) {
              return (
                <div className="container-fluid d-flex align-items-center">
                  <div className="falied-icon">
                    <span className="uploaded-text">Failed! </span>
                  </div>
                  <div
                    className="container-fluid ml-auto"
                    style={{ zIndex: 2 }}
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn-outline-primary retry-button btn-sm"
                        onClick={() => onClickRetry(details.index)}
                        id={`retry-button-${details.index}`}
                      >
                        Retry
                      </button>
                      <button
                        className="deleteBtn"
                        onClick={() => {
                          showDeleteModal(Id);
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              );
            } else if (
              fileUploadStatus === FileUploadStatus.fileUploadCanceled
            ) {
              return (
                <div className="container-fluid d-flex align-items-center">
                  <div className="falied-icon">
                    <span className="uploaded-text">Canceled! </span>
                  </div>
                  <div
                    className="container-fluid ml-auto"
                    style={{ zIndex: 2 }}
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn-outline-primary reupload-button btn-sm"
                        onClick={() => onClickRetry(details.index)}
                        id={`reupload-button-${details.index}`}
                      >
                        Reupload
                      </button>
                      <button
                        className="deleteBtn"
                        onClick={() => {
                          showDeleteModal(Id);
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              );
              // return (
              //   <div>
              //     <div className="falied-icon"><span className="uploaded-text">Canceled! </span></div>
              //     <button className="btn btn-outline-primary reupload-button btn-sm" onClick={() => onClickRetry(details.index)}>Re-upload</button>
              //     <button className="deleteBtn" onClick={() => { showDeleteModal(Id) }} ></button>
              //   </div>
              // )
            } else if (fileUploadStatus === FileUploadStatus.fileDuplicate) {
              //TEMP COMMENT --> VIEW BUTTON
              return (
                <div className="container d-flex justify-content-space-between">
                  <div className="col">
                    <div className="dublicate-icon">
                      <span className="uploaded-text">Duplicate Log</span>
                    </div>
                  </div>
                  <div
                    className="col d-flex justify-content-end"
                    style={{ zIndex: 2 }}
                  >
                    {/* <button className="btn btn-outline-primary view-button btn-sm">View</button> */}
                    <button
                      className="deleteBtn"
                      onClick={() => {
                        showDeleteModal(Id);
                      }}
                    ></button>
                  </div>
                </div>
              );
              // return (
              //   <div>
              //     <div className="dublicate-icon"><span className="uploaded-text">Duplicate Log</span></div>
              //     <button className="btn btn-outline-primary view-button btn-sm">View</button>
              //     <button className="deleteBtn" onClick={() => { showDeleteModal(Id) }} ></button>
              //   </div>
              // )
            } else {
              return <div></div>;
            }
          })()}
        </div>
      ),
      id: Id,
      lat: details.latitude,
      long: details.longitude,
      otherDetails: details,
    };
    return newFileInfo;
  };

  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [headerState, setHeaderState] = useState<boolean>(false);

  const columns: any = React.useMemo(
    () => [
      {
        id: "check_box",
        Header: (data: any) => {
          return (
            <>
              <input
                type="checkbox"
                checked={headerState}
                onChange={(event: any) =>
                  handleHeaderCheckboxChange(event, data)
                }
                id={`headerCheckboxId`}
                style={{ maxWidth: "40px" }}
              />
            </>
          );
        },
        Cell: (data: any) => {
          let flag = false;
          if (data.row.original?.otherDetails?.isUpdated) flag = true; //Disable check box if details updated
          return (
            <>
              <input
                type="checkbox"
                onChange={(event: any) =>
                  handleCheckboxChange(data.cell.row.id, event)
                }
                id={`rowCheckbox_${data.cell.row.id}`}
                style={{ maxWidth: "40px" }}
                disabled={flag}
              />
            </>
          );
        },
        //Header: "checkbox",
        accessor: "check_box",
        // isFixed: true,
        isDisplayed: true,
        sticky: "left",
        disableSortBy: true,
        maxWidth: 40,
        // maxWidth: 70,
        // minWidth: 40,
      },
      {
        id: "file_name",
        Header: "File Name",
        Cell: (data: any) => {
          ///console.log(data.cell.value)
          return <div className="text-wrap">{data.cell.value}</div>;
        },
        accessor: "file_name",
        isFixed: true,
        isDisplayed: true,
        sticky: "left",
        maxWidth: 264,
        Filter: TextSearchFilter,
        // className:"d-none"
      },
      {
        id: "departure_date_time",
        Header: "Departure Date & Time",
        accessor: "departure_date_time",
        isFixed: false,
        isDisplayed: true,
        maxWidth: 110,
      },
      {
        id: "nearest_airport",
        Header: "Nearest Airport",
        accessor: "nearest_airport",
        isFixed: false,
        isDisplayed: true,
        maxWidth: 80,
      },
      {
        id: "registration_no",
        Header: "Registration No",
        accessor: "registration_no",
        isFixed: false,
        isDisplayed: true,
        maxWidth: 80,
      },
      {
        id: "pilot_incharge",
        Header: "Pilot Incharge",
        accessor: "pilot_incharge",
        isFixed: false,
        isDisplayed: true,
        maxWidth: 100,
      },
      {
        id: "status",
        Header: " Status",
        accessor: "status",
        isFixed: false,
        isDisplayed: true,
        sticky: `right`,
        maxWidth: 300,
      },
    ],
    [headerState, selectedRowIds]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useSortBy,
    // useAbsoluteLayout,
    // useBlockLayout,
    useFlexLayout,
    usePagination,
    useRowSelect,
    useSticky
  );

  const { allColumns, setColumnOrder, setFilter } = tableInstance;

  const changeOrder = (order: any[]) => {
    // console.log(`order: `, order);
    setColumnOrder(order);
  };

  const onColumnSettingApply = (columnData: any) => {
    // console.log(`columnData: `, columnData);
    const orderOfColumns = columnData.map((column: any) => {
      return column.id;
    });
    changeOrder(orderOfColumns);
    setModalShow(false);
  };

  // useEffect(() => {
  // console.log(`pilotListValue: `, pilotListValue);
  // }, [pilotListValue]);

  // useEffect(() => {
  // console.log(`instructorListValue: `, instructorListValue);
  // }, [instructorListValue]);

  useEffect(() => {
    PilotService.getPilotType()
      .then((data) => {
        setPilotTypeListValue(data.pilotType);
        //console.log(data.pilotType)
      })
      .catch(() => { });

    PilotService.getActiveInstructors().then(data => {
      // console.log(`Active Instructors: `, data)
      setInstructorListValue(() =>
        data.data?.activeInstructor?.map((item: any) => {
          return {
            instructorId: item.instructorId,
            instructorName: item.lastName ? item.instructorName + " " + item.lastName : item.instructorName,
          }
        })
      )
    }).catch(error => {
      console.log(`Error getting instructor list: `, error);
    })

    PilotService.getActivePilots().then(data => {
      // console.log(`Active Pilots: `, data)
      setPilotListValue(() =>
        data.data.activePilots.map((item: any) => {
          return {
            pilotId: item.pilotId,
            pilotName: item.lastName ? item.pilotName + " " + item.lastName : item.pilotName,
          }
        })
      )
    }).catch(error => {
      console.log(`Error getting pilot list: `, error);
    })

    AircraftService.getAircraftList().then((data) => {
      setAircraftListValue(data.aircraftList);
    })
      .catch(() => { });

    CommonService.getExerciseList()
      .then((data) => {
        setExerciseListValue(data);
        // console.log(data)
      })
      .catch(() => { });
  }, [selectedRowIds]);

  const rowClick = (rowid: any, data: any) => {
    setRowIndex(rowid);
  };

  const handleCheckboxChange = (
    rowId: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(`Called`)
    const isRowIdSelected = selectedRowIds.includes(rowId);
    if (event.target.checked) {
      if (!isRowIdSelected) {
        setSelectedRowIds((prevIds) => [...prevIds, rowId]);
      }
    } else {
      // If checkbox is unchecked, remove rowId from the array
      setSelectedRowIds((prevIds) => prevIds.filter((id) => id !== rowId));
    }
  };

  const handleHeaderCheckboxChange = (event: any, data: any) => {
    setHeaderState(!headerState);
    if (event.target.checked) {
      setHeaderState(true);
      data.rows.forEach((element: any) => {
        const checkboxId = `rowCheckbox_${element.id}`;
        const checkbox = document.getElementById(
          checkboxId
        ) as HTMLInputElement;
        if (!selectedRowIds.includes(element.id) && !checkbox.disabled) {
          setSelectedRowIds((prevIds) => [...prevIds, element.id]);
        }
      });
    } else {
      setHeaderState(false);
      setSelectedRowIds([]);
    }
  };

  useLayoutEffect(() => {
    // If table and selectedRowid array are empty , the header checkbox should be unchecked
    if (tableInstance.rows.length !== 0 && selectedRowIds.length !== 0) {
      let disabledBoxesCount: number = 0;
      tableInstance.rows.forEach((row: any) => {
        const checkboxId = `rowCheckbox_${row.id}`;
        const checkbox = document.getElementById(
          checkboxId
        ) as HTMLInputElement;
        if (checkbox.disabled) {
          disabledBoxesCount++;
        }
      });
      if (
        selectedRowIds.length !==
        tableInstance.rows.length - disabledBoxesCount
      ) {
        setHeaderState(false);
      } else {
        setHeaderState(true);
      }
    } else {
      setHeaderState(false);
    }

    // Update the checked state of individual checkboxes based on selectedRowIds
    tableInstance.rows.forEach((row: any) => {
      const checkboxId = `rowCheckbox_${row.id}`;
      const checkbox = document.getElementById(checkboxId) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = selectedRowIds.includes(row.id) as boolean;
      }
    });
  }, [selectedRowIds, headerState]);

  let newSelectedRowIds: any[] = []; // new array for Mismatch Case

  const bulkAssign = () => {
    selectedRowIds.forEach((element: any) => {
      if (data[element].id === "0" || !data[element].id) {
        setBulkAssignMismatchAlert(true);
      } else {
        newSelectedRowIds.push(element);
      }
    });

    setIsMultipleFilesSelected(true);

    let dataE = data[selectedRowIds[0]];

    if (0 !== newSelectedRowIds.length) {
      dataE = data[newSelectedRowIds[0]];
    }

    let filedata = {
      Id: dataE.id,
      // fileName: selectedRowIds.length + " selected file",
      fileName: dataE.file_name,
      totalFiles: newSelectedRowIds.length,
      pos: { latitude: dataE.lat, longitude: dataE.long },
    };

    let lat: Number = parseFloat(dataE.lat);
    let long: Number = parseFloat(dataE.long);
    setIsLoading(true);
    DebriefService.getAirportList(lat, long)
      .then((res) => {
        // console.log("Airport list");
        // console.log(`AL 2: `, res)
        // console.log(res)
        setIsLoading(false);
        setAirportList(res);
        SetFileDetails(filedata);
        if (selectedRowIds.length === newSelectedRowIds.length) {
          setAssignModalShow(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    // setAssignModalShow(true)
  };

  const updateMultipleFiles = async (updateFileInfo: any) => {
    //   console.log("Multiple files")
    selectedRowIds.forEach(async (e: any) => {
      const updateInfoCopy = { ...updateFileInfo };

      let pos: any = {};
      let element = data[e];
      //new code
      // console.log(`Element: `, element);
      //new code
      let file = element.otherDetails.file;
      pos["latitude"] = element.lat;
      pos["longitude"] = element.long;
      pos["depDate"] = element.departure_date_time;
      pos["pilot"] = updateInfoCopy.pilotName;
      pos["regNo"] = updateInfoCopy.aircrafTail;
      pos["airport"] = updateInfoCopy.airportcode;
      updateInfoCopy["rowid"] = element.id;
      updateInfoCopy["index"] = e;
      updateInfoCopy["file"] = file;
      try {
        let res = await DebriefService.updateFileDetails(updateInfoCopy);
        pos["isUpdated"] = true;
        updateFilelistData(
          res.file,
          FileUploadStatus.fileProcessed,
          res.fileID,
          +res.index,
          pos
        );
        setIsSucessVisible(true);
        let clockInt = setInterval(() => {
          setIsSucessVisible(false);
          clearInterval(clockInt);
        }, 4000);
        let clockInt1 = setInterval(() => {
          setFilter("file_name", searchText);
          clearInterval(clockInt1);
        }, 1);
        DebriefService.sendToRabbitMQ(res.fileID)
          .then((response) => {
            //console.log("Sent to que")
            // console.log(response)
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } catch (err: any) {
        console.log("Error updating log file details: ", err);
        // console.log(err);

        updateFilelistData(
          err.file,
          FileUploadStatus.fileUploaded,
          err.fileID,
          err.index,
          pos
        );
      }
    });
    setSelectedRowIds([]);
  };

  const showDeletModalForMultipleFile = () => {
    setIsMultipleFilesSelected(true);
    setDeleteModalShow(true);
  };

  const deleteSelectedFiles = async () => {
    setIsLoading(true);
    let indexNum = data.length - selectedRowIds.length;
    selectedRowIds.sort((a, b) => Number(a) - Number(b));
    selectedRowIds.reverse();

    for (let index = 0; index < selectedRowIds.length; index++) {
      const e = selectedRowIds[index];
      let element = data[e];
      try {
        if (element.id && element.id !== "0") {
          await DebriefService.deleteFileDetails(element.id);
        }
        //console.log(res)
        deleteFileFromList(+e);
      } catch (error: any) {
        // console.log(`Multiple Delete Error: `, error)
        deleteFileFromList(+e);

        // if (error.err.response.data.code === -5064) {
        // deleteFileFromList(+error.index);
        // }
      }
    }
    setIsDeleteVisible(true);
    let clockInt = setInterval(() => {
      setIsDeleteVisible(false);
      clearInterval(clockInt);
    }, 5000);
    let clockInt1 = setInterval(() => {
      setFilter("file_name", searchText);
      clearInterval(clockInt1);
      setIsLoading(false);
    }, 100);
    setSelectedRowIds([]);
    setIndexNumber(indexNum);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [showRetryButton, setShowRetryButton] = useState<boolean>(false);
  const [showReuploadButton, setShowReuploadButton] = useState<boolean>(false);
  const [showUpdateLogButton, setShowUpdateLogButton] =
    useState<boolean>(false);
  const [showCancelUploadButton, setShowCancelUploadButton] =
    useState<boolean>(false);

  useEffect(() => {
    // console.log(`tableInstance.rows[0]: `, tableInstance.rows[0]);
    // For Reference:
    // values.status.props.children.props.children[0].props.children.props.children === "Uploaded "
    // values.status.props.children.props.children[0].props.children.props.children === "Falied! "
    // values.status.props.children.props.children[0].props.children.props.children === "Duplicate Log"
    // values.status.props.children.props.children[0].props.children.props.children === "Canceled! "
    // values.status.props.children.props.children[0].props.children.props ???? -> For Uploading Files

    let duplicateFlag = false;
    for (let i = 0; i < selectedRowIds.length; i++) {
      let element = selectedRowIds[i];
      const statusValue =
        tableInstance?.rows[element]?.values.status.props.children.props
          .children[0].props.children.props?.children;

      // console.log(`statusValue: `, statusValue);

      //creating this seperate IF Statement because Update Log button must be visible if EVEN 1 of selected logs is 'Updatable'
      if (statusValue?.props?.children === "Uploaded ") {
        // console.log(`Uploaded`)
        setShowUpdateLogButton(true);
        // setShowRetryButton(false);
        setShowReuploadButton(false);
        setShowCancelUploadButton(false);
        break;
      }

      if (statusValue === undefined) {
        // console.log(`still uploading: unefined`)
        setShowCancelUploadButton(true);
        // setShowRetryButton(false);
        setShowReuploadButton(false);
        setShowUpdateLogButton(false);
        break;
      }

      if (statusValue?.props?.children === "Duplicate Log") {
        // console.log(`Duplicate Log`)
        duplicateFlag = true;
        // setShowRetryButton(false);
        setShowReuploadButton(false);
        setShowUpdateLogButton(false);
        setShowCancelUploadButton(false);
        continue;
      }

      if (!duplicateFlag) {
        // console.log(`!duplicateFlag`)
        // setShowRetryButton(true);
        setShowReuploadButton(true);
        setShowUpdateLogButton(true);
        setShowCancelUploadButton(false);

        if (statusValue !== "Falied! " && statusValue !== undefined) {
          // console.log(`Failed`)
          // setShowRetryButton(false);
          setShowReuploadButton(true);
          setShowUpdateLogButton(false);
          setShowCancelUploadButton(false);
        } else if (statusValue !== "Canceled! " && statusValue !== undefined) {
          // console.log(`Canceled`)
          setShowReuploadButton(false);
          setShowUpdateLogButton(false);
          setShowCancelUploadButton(false);
        }
      }
    }
  }, [selectedRowIds, tableInstance.rows]); // Change this to update the buttons of BulkAssign Div

  const [showBatchActionModal, setShowBatchActionModal] = useState(false);

  const openBatchActionModal = () => setShowBatchActionModal(true);
  const closeBatchActionModal = () => setShowBatchActionModal(false);

  // useEffect(()=> {
  //   console.log(`data: `, data);
  // }, [data])

  // Function to retry uploading a `Failed` upload log
  const onClickRetry = async (ind: any) => {
    // console.log(`onClickRetry called for index: `, ind);
    let rowData: any = tableInstance.rows[ind].original;
    let otherDetails = rowData.otherDetails;
    let axiosSource = axios.CancelToken.source();
    otherDetails.axiosSource = axiosSource;
    let newData = addFilesToTable(
      otherDetails.file,
      FileUploadStatus.fileUploading,
      "0",
      {
        axiosSource: axiosSource,
        index: ind,
        isApiCalled: true,
        file: otherDetails.file,
      }
    );

    setData((data) => [...data.slice(0, ind), newData, ...data.slice(ind + 1)]);

    try {
      let res: any = await DebriefService.uploadDebriefFile(
        rowData.otherDetails.file,
        rowData.otherDetails
      );

      if (res.code === 6069) {
        //If file is duplicate
        // console.log(`res.id: `, res.id)
        updateFilelistData(
          rowData.otherDetails.file,
          FileUploadStatus.fileDuplicate,
          res.id,
          rowData.otherDetails.index,
          otherDetails
        );
      } else {
        const d = new Date(res.departureTime);
        let str: any = d.toString().split(" ");
        let year: any = str[3].split("");
        let date =
          str[2] +
          " " +
          str[1] +
          " " +
          year[year.length - 2] +
          year[year.length - 1] +
          ", " +
          str[4] +
          " IST";

        otherDetails["latitude"] = res.latitude.trim();
        otherDetails["longitude"] = res.longitude.trim();
        otherDetails["depDate"] = date;
        //console.log(res)
        updateFilelistData(
          rowData.otherDetails.file,
          FileUploadStatus.fileUploaded,
          res.id,
          rowData.otherDetails.index,
          otherDetails
        );
      }
    } catch (err: any) {
      //console.log("Index "+ e.otherDetails.index)
      console.log(err);
      if (err?.err?.code === "ERR_CANCELED") {
        //IF uploading is canceled
        // console.log(err);
        updateFilelistData(
          rowData.otherDetails.file,
          FileUploadStatus.fileUploadCanceled,
          "0",
          rowData.otherDetails.index,
          otherDetails
        );
      } else {
        updateFilelistData(
          rowData.otherDetails.file,
          FileUploadStatus.fileUploadFailed,
          "0",
          rowData.otherDetails.index,
          otherDetails
        );
      }
    }
  };

  // useEffect(() => {
  //   console.log(`TI: `, tableInstance)
  // }, [tableInstance.rows])

  // const onBulkRetryClick = () => {
  //   selectedRowIds.forEach((element: any) => {
  //     onClickRetry(element);
  //   })
  //   setHeaderState(false);
  //   setSelectedRowIds([]);
  // };

  const onBulkReuploadClick = () => {
    selectedRowIds.forEach((element: any) => {
      const currentRow = tableInstance.data[element];
      const buttonElementId = currentRow?.status?.props?.children?.props?.children[1]?.props?.children?.props?.children[0]?.props?.id;
      const buttonElement = document.getElementById(buttonElementId);

      if (buttonElement) {
        buttonElement?.click();
      }
    });

    setHeaderState(false);
    setSelectedRowIds([]);
  }

  const onBulkCancelClick = () => {
    selectedRowIds.forEach((element: any) => {
      const currentRow = tableInstance.data[element];
      abortApi(currentRow?.otherDetails?.axiosSource);
    });
  }

  return (
    <>
      {isLoading && (
        <div className="spinnerWrap">
          {" "}
          <LoadSpinnerBig />
        </div>
      )}
      <div className="uploadlogs-wrapper">
        <section className="file-uploader">
          <h3>
            FILE UPLOADER{" "}
            {/**
             * a link of all the supported formats of the input data (eg: G1000 for now); more might be supported later
             */}
            <a href="">
              ( <i className="icon-info"> </i> <span style={{ marginLeft: '3px' }}>Supported Avionics</span> )
            </a>
          </h3>
          <label
            htmlFor="flightReport"
            onDragOver={handleDragOverReport}
            onDrop={handleDropReport}
            className="file-label-dropzone"
            id="flightReportContainer"
          >
            <div className="upload-block-wrap">
              <p id="reportFileName">
                Drop files here to upload, or{" "}
                <u className="text-deco">Click here to select log files</u>
              </p>
              {/* <p style={{color:"grey"}}>Limit 200 MB per file</p> */}
              <input
                type="file"
                id="flightReport"
                name="flightReport"
                onChange={reportFileLoad}
                ref={flightReportRef}
                multiple={true}
                accept=".csv"
              />
              {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} /> */}
            </div>
          </label>
          <p>Supported file types : CSV</p>
        </section>
        <section className="upload-status">
          <h3 style={{ marginLeft: '32px' }}>UPLOAD STATUS</h3>
          <div className="import-filter-wrap">
            <div></div>
            <input
              type="text"
              className="import-file-search"
              placeholder="Search by file name"
              onChange={onSearchInputChange}
            />
            <Button
              variant="secondary"
              className="f360-btn-with-icon-light me-3 column-btn btn-customise-table"
              onClick={() => setModalShow(true)}
            >
              <i className="import-setting-icon"></i>
              Customise Table
            </Button>
          </div>

          {selectedRowIds.length > 0 && (
            <div className="container-fluid rows-selected d-flex align-items-center">
              {/* <span className="text-white rows-selected-text">{`${selectedRowIds.length} items selected`}</span> */}
              <div className="text-white rows-selected-text col-xs-2 mx-2">{`${selectedRowIds.length} items selected`}</div>
              <div className="vertical-line-left"></div>
              {/* <button className="btn btn-primary mx-2" onClick={(event: any) => { handleHeaderCheckboxChange(event, data) }}>Select All ({tableInstance.rows.length})</button> */}

              <div className="container-fluid ml-auto">
                {!isSmallScreen ? (
                  <div className="rightEndButtons d-flex justify-content-end align-items-center">
                    {showUpdateLogButton && (
                      <button
                        className="btn btn-primary mx-2"
                        onClick={bulkAssign}
                      >
                        ✓ Assign Logs
                      </button>
                    )}
                    {/*
                    {showRetryButton &&
                      <button className="btn btn-primary mx-2" onClick={onBulkRetryClick}> ↻ Retry</button>
                    }
                    */}

                    {showReuploadButton && (
                      <button
                        className="btn btn-primary mx-2"
                        onClick={onBulkReuploadClick}
                      >
                        {" "}
                        ↻ Reupload
                      </button>
                    )}
                    {showCancelUploadButton && (
                      <button
                        className="btn btn-primary mx-2"
                        onClick={onBulkCancelClick}>
                        {" "}
                        X Cancel Uploads
                      </button>
                    )}
                    <button
                      className="btn btn-primary mx-4"
                      onClick={showDeletModalForMultipleFile}
                    >
                      Delete Logs
                    </button>
                    <div className="vertical-line-right"></div>
                    <button
                      className="btn btn-primary mx-4"
                      onClick={() => {
                        setSelectedRowIds([]);
                      }}
                    >
                      Exit
                    </button>
                    {/* </div> */}
                  </div>
                ) : (
                  <div className="rightEndButtons d-flex justify-content-end align-items-center">
                    <button
                      className="btn-option mx-2"
                      onClick={() => {
                        openBatchActionModal();
                      }}
                    ></button>
                    <Modal
                      show={showBatchActionModal}
                      onHide={closeBatchActionModal}
                      dialogClassName="bottom-modal"
                      aria-labelledby="contained-modal-title-vcenter"
                    >
                      <Modal.Header closeButton className="bottom-modal-header">
                        <Modal.Title className="assign-modal-title">
                          Batch Actions
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="text-center">
                          {showUpdateLogButton && (
                            <button
                              className="btn btn-primary my-3"
                              style={{ width: "90%" }}
                              onClick={bulkAssign}
                            >
                              ✓ Assign Logs
                            </button>
                          )}
                          {/*
                          {showRetryButton &&
                            <button className="btn btn-primary my-3" style={{ width: '90%' }} onClick={() => { onBulkRetryClick(); closeBatchActionModal(); }}> ↻ Retry</button>
                          }
                          */}
                          {showReuploadButton && (
                            <button
                              className="btn btn-primary my-3"
                              style={{ width: "90%" }}
                              onClick={() => {
                                onBulkReuploadClick();
                                closeBatchActionModal();
                              }}
                            >
                              {" "}
                              ↻ Reupload
                            </button>
                          )}
                          <br />
                          <button
                            className="btn btn-danger my-2"
                            style={{ width: "90%" }}
                            onClick={showDeletModalForMultipleFile}
                          >
                            {` `} Delete Logs
                          </button>
                        </div>
                        <div className="my-4"></div> {/*to add bottom margin*/}
                      </Modal.Body>
                    </Modal>
                    <div className="vertical-line-right"></div>
                    <button
                      className="btn btn-primary mx-4"
                      onClick={() => {
                        setSelectedRowIds([]);
                      }}
                    >
                      Exit
                    </button>
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="import-upload-table-wrap">
            <div className="table-wrapper">
              <StickyTable
                tableInstance={tableInstance}
                searchField={SearchField}
                rowClick={rowClick}
                showPaginationDiv={false}
              ></StickyTable>
            </div>
            {isSuccessVisible && (
              <div className="main-bg1 ">
                <div className="side-fill"></div>{" "}
                <img src={successICon} className="svg-success"></img>
                <div className="text-successsent1">
                  <span className="suc-bold">Success :</span> Logs have been
                  successfully assigned and are now queued for debriefing
                </div>
              </div>
            )}
            {isDeleteVisible && (
              <div className="main-bg1 width-335">
                <div className="side-fill"></div>{" "}
                <img src={successICon} className="svg-success"></img>
                <div className="text-successsent1">
                  <span className="suc-bold">Success :</span> Flight logs
                  deleted successfully!
                </div>
              </div>
            )}
            <ColumnSettings
              show={modalShow}
              allColumns={allColumns}
              columnsettingapply={(columnData: any) =>
                onColumnSettingApply(columnData)
              }
              onHide={() => setModalShow(false)}
            ></ColumnSettings>
            {!data.length && (
              <div className="text-center no-files-text">
                No Files Uploaded!
              </div>
            )}
            <UpdateFileInfoModal
              show={assignModalShow}
              // show={true}
              callBackUpdate={updateDetails}
              onHide={() => setAssignModalShow(false)}
              pilotTypeListValue={pilotTypeListValue}
              pilotListValue={pilotListValue}
              instructorListValue={instructorListValue}
              aircraftListValue={aircraftListValue}
              airportList={airportList}
              fileInfo={fileDetails}
              exerciseListValue={exerciseListValue}
              updateMultipleFiles={updateMultipleFiles}
              ismultiplefilesselected={isMultipleFilesSelected}
            ></UpdateFileInfoModal>

            <BulkAssignMismatchAlert
              show={bulkAssignMismatchAlert}
              onHide={() => setBulkAssignMismatchAlert(false)}
              newSelectedRowIds={newSelectedRowIds}
              updateMultipleCallback={() => {
                setBulkAssignMismatchAlert(false);
                setSelectedRowIds(newSelectedRowIds);
                bulkAssign();
                if (newSelectedRowIds.length > 0) {
                  setAssignModalShow(true);
                }
              }}
            ></BulkAssignMismatchAlert>

            <DeleteLogsModal
              show={deleteModalShow}
              deleteCallBack={deleteFile}
              fileId={fileId}
              onHide={() => setDeleteModalShow(false)}
              ismultiplefilesselected={isMultipleFilesSelected}
              deleteSelectedFiles={deleteSelectedFiles}
              rowIndex={rowIndex}
            ></DeleteLogsModal>
          </div>
        </section>
      </div>
    </>
  );
};
export default UploadLogs;
