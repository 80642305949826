import fullScreenWindow from "../../../../../common/utils/fullScreenWindow"
import React from "react"
import Plotlygraph from "../../graphs/Plotlygraph"

const dayjs = require(`dayjs`);


/**
 * Parses an array of objects containing DateTimeUtc property into an array of JavaScript Date objects.
 * @param {Array} inputArray - The input array of objects.
 * @returns {Array} - An array of JavaScript Date objects.
 */
export function parseDateTimeArray(inputArray: any[]) {

    // console.log(`inputArray: `, inputArray);

    const timeStringArray = inputArray.map((item) => {
        // console.log(`Initial: `, item["DateTimeUtc"])
        let timeStamp = item["DateTimeUtc"];
        let date = timeStamp.split(" ")[0].split("/");
        if (date[0].length === 1) {
            date[0] = '0' + date[0];
        }
        if (date[1].length === 1) {
            date[1] = '0' + date[1];
        }
        // console.log(`date: `, date)
        let dateTime = date[2] + "-" + date[0] + "-" + date[1] + "T" + timeStamp.split(" ")[1] + "+00:00";
        // console.log(`processed: `, dateTime)
        return dateTime;
    });

    // Parsing date+time to date object using dayjs
    const parsedArray = timeStringArray.map((dateTimeString) => {
        // console.log(`dateTimeString: `, dateTimeString)
        const dateObject = dayjs(dateTimeString, 'YYYY-MM-DD HH:mm:ss');
        // console.log(`dateObject: `, dateObject)
        // console.log(`toDate: `, dateObject.toDate())
        return dateObject.toDate(); // Convert to a JavaScript Date object
    });

    // console.log(`parsedArray: `, parsedArray);

    return parsedArray;
}


const IasVsAltVsTimeContainer = (props: any) => {

    /**
 * Opens a new tab with the specified URL for the graph.
 * @returns {void}
 */
    // const newTab = () => {
    //     window.open('/special-tabs/graph', '_blank', 'toolbar=no, location=no, status=no');
    // };

    const newTab = () => {
        window.open(
            '/special-tabs/graph',
            '_blank',
            'toolbar=no, location=no, status=no, width=800, height=300, top=00, left=0, resizable=yes, scrollbars=yes'
        );
    };


    return (
        <>
            <div className="debrief-cards" id={props.id} >
                <div className="debrief-cards-handle">
                    IAS Vs Alt Vs Time
                    <div className="button-holder d-flex">
                        {/* <button className="btn-customize"></button> */}
                        <button className="btn-maximize" title="Fullscreen" onClick={() => fullScreenWindow(props.id)}></button>
                        <button className="btn-new-tab" title="Open in new tab" onClick={newTab}></button>
                    </div>
                </div>
                <div className="debrief-cards-content">
                    <Plotlygraph
                        xaxisArray={props.lclTimeArray}
                        y1axisArray={props.iasArray}
                        y2axisArray={props.altMSLArray}
                        xlabel='Local Time'
                        y1label='IAS'
                        y2label='Altitude'
                        viewer={props.viewer}
                        isReportBeingGeneratedRef={props.isReportBeingGeneratedRef}
                    />
                </div>
            </div>
        </>
    )
}

export default React.memo(IasVsAltVsTimeContainer);