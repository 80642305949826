import React, { useEffect, useState, useMemo, useRef, useLayoutEffect } from "react";
import "./DebriefPlayer.scss";
import { Accordion, Card } from "react-bootstrap";
import DebriefService from "../../../../../services/debrief.service";
import { addTrackEntity, csvJSonArray, GetJulianDateFromTimeStamp, PlotWallNPolyline, ReadFile, } from "../../../../../common/utils/cesiumDataHandler";
import LoadSpinnerBig from "../../../../../common/components/loadSpinner/loadSpinnerBig";
import { changeDateFormat, getTime, segregateGraphData, TabDataBrodacast, TabDataMsgType } from "../../../../../common/utils/utils";
import DraggableCard from "./DraggableCard";
import PfdCard from "./PfdCard";
import IasVsAltVsTimeContainer from "./IasVsAltVsTimeContainer";
import FlightPath from "./FlightPath";
import { parseDateTimeArray } from "./IasVsAltVsTimeContainer";
import SliderComp from "./SliderComp";
import { SceneMode, JulianDate } from "cesium";
import GaugeGraphPlot from "./GaugeGraphPlot";
import StickyTable from "../../../../../common/components/StickyTable/StickyTable";
import ScoreTableInstances from './ScoreTableInstances';
import ScoreTableInstancesChimes from './ScoreTableInstancesChimes';
import ExceedancesAndAlertsComp from "./ExceedancesAndAlertsComp";
import { getLocalStorage } from "../../../../../common/utils/localStorageHandler";

import aerxlabsLogo from '../../../../../assets/images/icon-aerx-logo@3x.png'
import jsPDF from "jspdf";
import Plotly from "plotly.js";
// import Plotl


let segregatedDataCopy: any, tailNumber: any, startTime: any;

// const DraggableCard = React.lazy(() => import('./DraggableCard'));

const DebriefPlayer = (props: any) => {

    const companyId = getLocalStorage("authTokens", "").companyid;

    // console.log(`Score: `, props.currentCsvFileInfo?.avgOverallScore?.props?.children?.props?.children);

    // console.log(`Player props status: `, props.status);

    // console.log(`Player SegregatedData: `, props.segregatedData);

    const [showLeftSection, setShowLeftSection] = useState(true);
    // const[playerSpeed, setPlayerSpeed] = useState<Number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [showRightSection, setShowRightSection] = useState(false);
    // const [updatedSegregatedData, setUpdatedSegregatedData] = useState<any>([])
    const [isAllPhasesChecked, setIsAllPhasesChecked] = useState(true)
    // const [isDebriefPause, setIsDebriefPause] = useState(false)
    const [isPointerEventDisable, setIsPointerEventDisable] = useState(true)
    const [showFlightPath3D, setShowFlightPath3D] = useState(true);
    const [trackEntity, setTrackEntity] = useState(true)
    const currentCsvFileInfo = props.currentCsvFileInfo;
    const [renderAgain, setRenderAgain] = useState(false);
    const [reset, setReset] = useState(false);

    useEffect(() => {
        // console.log(`Inside useEffect`);
        if (props.status === 4) {
            // console.log(`Inside IF STATEMENT`);
            setShowRightSection(true);
        }
        //change .debrief-player .main-panel {width: 100%}
        // change .debrief-player .right-section .side-panel-overlay {display: none}
    }, [props.status]);

    const toggleLeftSection = () => {
        setShowLeftSection(!showLeftSection)
        setTimeout(renderGraphs, 100)    //delay the re-rendering by 100 milliseconds
    }

    const renderGraphs = () => {
        setRenderAgain(!renderAgain)
    }

    const closePlayer = () => {
        props.closePlayer();
        setTrackEntity(true);
        setReset(!reset);
        TabDataBrodacast.postMessage({ id: TabDataMsgType.closeNewTabs, data: "" })
    }

    const playNextDebrief = () => {
        resetMainPanel()
        let rowId = props.currentCsvRowId + 1

        if (rowId >= props.debriefFileList.length) {

            return
        }

        getCsvFileNStartPFD(rowId)
    }

    const IasVsAltVsTimeDataChange = (seqId: any) => {
        props.segregatedData.filter((val: any, index: any) => val.sequenceId === seqId).map((val: any) => {
            let currentVisibility = val.isVisible
            val.isVisible = !val.isVisible;
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdSequenceIdShowHidePhase, data: { sequenceId: seqId, visibility: !currentVisibility } })
            if (val.subPhaseMapping.length !== 0) {
                props.segregatedData.filter((val1: any, index: any) => val.subPhaseMapping.includes(val1.sequenceId)).map((val1: any) => {
                    val1.isVisible = !currentVisibility;
                    TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdSequenceIdShowHidePhase, data: { sequenceId: val1.sequenceId, visibility: !currentVisibility } })
                })
            } else {
                subPhaseParentChecked(val.partitionIndex)
            }
        })

        AllPhasesChecked()
    }

    const changeVisibility = () => {
        // setRenderAgain(!renderAgain);
        props.segregatedData.filter((val: any) => val.cesiumEntity3D !== null).map((e: any) => {
            if (e.cesiumEntity3D) {
                e.cesiumEntity3D.map((entity: any) => {
                    //  console.log("The visibility change = "+e.isVisible);
                    entity.show = e.isVisible;
                })
            }
        })
        setRenderAgain(!renderAgain);
    }

    const pillBgColor = (score: any) => {
        if (score <= 5) {
            return `#FF0000`;
        } else if (score > 5 && score < 8.5) {
            return `#eb8a2f`;
        } else {
            return `#39b34a`;
        }
    };

    const playPrevDebrief = () => {
        resetMainPanel()
        let rowId = props.currentCsvRowId - 1

        if (rowId < 0) {

            return
        }
        getCsvFileNStartPFD(rowId)
    }
    /**
     * 
     * @param rowId -index number of row oc which clicked
     */
    const getCsvFileNStartPFD = (rowId: any) => {
        setIsLoading(true);
        DebriefService.updateRecentlyPlayed(props.debriefFileList[rowId].debriefId).then((res) => {
            // console.log("Updated ");
            // console.log(res)
        }).catch((err) => { })
        //DebriefService.getCsvFile(props.debriefFileList[rowId].blobUrl).then((res) => {
        //res- csv file got from url
        DebriefService.getCsvJsonFromServer(props.debriefFileList[rowId].debriefId).then((res) => {
            props.setNewCsvFileData(res.data);
            props.setNewCurrentCsvRowId(rowId)
            props.SetNewCurrentCsvFileInfo(props.debriefFileList[rowId]);
            // console.log("Debrief (ID)")
            // console.log(props.debriefFileList[rowId].debriefId)
            DebriefService.getDebriefDetailsInfo(props.debriefFileList[rowId].debriefId).then((response) => {
                // console.log("Got the data in response")
                // console.log(response)
                ReadFile(res.data, props.viewer, PlotWallAndAddFlightModel, response).then(() => {

                    let timeMin = GetJulianDateFromTimeStamp(csvJSonArray[0]);
                    let timeMax = GetJulianDateFromTimeStamp(csvJSonArray[csvJSonArray.length - 1] || csvJSonArray[csvJSonArray.length - 11])


                    let diffSeconds = JulianDate.secondsDifference(timeMax, timeMin);
                    // console.log(`xxxxxxxxxxxxxxxxxxxxxxxxx`)
                    // console.log(`range: `, diffSeconds)
                    props.setNewCsvFileRange(diffSeconds);

                }).catch((err) => { console.log(err) })
            }).catch((err) => {
                console.log("Error getting debrief details information");
                console.log(err)
            })
        }).catch((err) => {
            console.log("Error getting CSV data")
            console.log(err)
        })
    }

    /**
     *  plots flight and 
     *  adds flight model
     *  adds onTick event listner to animate over time
     * @param analysisresponse- response got from debriefDetailsInfo 
     */
    const PlotWallAndAddFlightModel = (analysisResponse: any) => {
        segregateGraphData(csvJSonArray, analysisResponse, props.setSegregatedData, props.setAnalysisData).then(() => {
            let flag: any = false;
            props.setSegregatedData((segregatedData: any) => {//setSegregate function of useSate is used to get updated value of variable
                if (!flag) {
                    // Added condition so that this shoukd not be executed twice
                    let entityArray: any = [];
                    //Destroys  previous entities if they are existing
                    props.viewer3D?.entities.removeAll()
                    segregatedData.map((e: any) => {
                        // if (e.subPhaseMapping.length === 0) {
                        if (e.partitionIndex === null) {
                            let cordArray: any = []//add  Longitude, Latitude,Altitude,Longitude...  to cordArray for each segment
                            e.data.forEach(function (item: any, index: any, arr: any) {
                                cordArray.push(+item.Longitude);//Adding coordinates to cordArray
                                cordArray.push(+item.Latitude);
                                cordArray.push(+item.AltGPS);
                            })
                            let segWall = PlotWallNPolyline(props.viewer3D, cordArray, e.label);
                            // entityArr.push(segWall[0]);
                            entityArray.push(segWall[0])
                            e.cesiumEntity3D = segWall;//assign array containing polyline and wall to cesiumEntity of each segment which is used to show and hide path
                        }
                    })
                    //props.viewer3D.flyTo(entityArray);
                    addTrackEntity(props.viewer3D).then((entity: any) => {// Adds flight entity and set the tracks the entity 
                        props.setViewer2D(entity);
                        // 
                        setTrackEntity(true)
                        setReset(!reset)
                        setIsLoading(false);
                        setTimeout(() => {
                            props.viewer3D.camera.zoomOut(50);// zoom out function of camera
                        }, 1)

                    })
                    checkAllPhases();
                    flag = true;
                }
                return segregatedData;
            });
        }).catch((err) => {
            console.log(err)
        })
    }

    const subPhaseParentChecked = (partitionIndex: number) => {
        props.segregatedData.filter((val: any, index: any) => val.partitionIndex === partitionIndex && val.isVisible === false).map((val: any) => {
            props.segregatedData.filter((val1: any, index: any) => val1.sequenceId === partitionIndex).map((val1: any) => {
                val1.isVisible = false
                return
            })
        })

        const filteredData = props.segregatedData.filter((val: any) => val.partitionIndex === partitionIndex && val.isVisible === true);
        const countAfterFilter = filteredData.length;

        props.segregatedData.filter((val1: any, index: any) => val1.sequenceId === partitionIndex).map((val1: any) => {
            if (countAfterFilter === val1.subPhaseMapping.length) {
                val1.isVisible = true
                return
            }
        })
    }

    const AllPhasesChecked = () => {
        let check = true
        props.segregatedData.filter((val: any, index: any) => val.isVisible === false).map((val: any) => {
            setIsAllPhasesChecked(false)
            check = false;
            //changeVisibility();
            return
        })
        if (!check) {
            changeVisibility();
            return
        }
        setIsAllPhasesChecked(true)
        changeVisibility();
    }

    const checkAllPhases = () => {
        if (!isAllPhasesChecked) {
            setIsAllPhasesChecked(!isAllPhasesChecked)
            props.segregatedData.filter((val: any, index: any) => val.isVisible === false).map((val: any) => {
                val.isVisible = true
            })
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdAllPhasesChecked, data: "" })
        } else {
            setIsAllPhasesChecked(!isAllPhasesChecked)
            props.segregatedData.filter((val: any, index: any) => val.isVisible === true).map((val: any) => {
                val.isVisible = false
            })
            TabDataBrodacast.postMessage({ id: TabDataMsgType.setFpdAllPhasesUnChecked, data: "" })
        }
        AllPhasesChecked()
    }

    const keyBoardDownEvents = (event: any) => {
        if (event.key === 'z' || event.key === 'Z') {
            setIsPointerEventDisable(false)
        }
    }

    const keyBoardUpEvents = (event: any) => {
        if (event.key === 'z' || event.key === 'Z') {
            setIsPointerEventDisable(true)
            //console.log("z release")
        }
    }

    const changeSceneMode = () => {

        if (showFlightPath3D) {
            props.viewer3D.scene.mode = SceneMode.SCENE2D
            // props.viewer3D.trackedEntity = props.viewer2D;
            if (trackEntity) props.viewer3D.trackedEntity = props.viewer2D;
            // setTrackEntity(true)
            setShowFlightPath3D(false)
        } else {
            props.viewer3D.scene.mode = SceneMode.SCENE3D;

            // props.viewer3D.trackedEntity = props.viewer2D;
            if (trackEntity) props.viewer3D.trackedEntity = props.viewer2D;
            // setTrackEntity(true)
            setShowFlightPath3D(true)
        }
        //setShowFlightPath3D(!showFlightPath3D)
    }

    const toggleTrack = () => {
        // console.log(`toggleTrack Function DebriefPlayer`);
        // console.log(`DebriefPlayer Props: `, props)
        //console.log("Called ")
        if (props.viewer3D.trackedEntity) {
            // console.log("Untracked ")
            props.viewer3D.trackedEntity = null;

            //console.log(props.viewer3D.trackedEntity )
            setTrackEntity(false)
        } else {
            // console.log("Tracked ")
            props.viewer3D.trackedEntity = props.viewer2D

            setTimeout(() => {
                props.viewer3D.camera.zoomOut(50);
                // clearInterval(clockInt1); 
            }, 1)
            setTrackEntity(true)
        }

    }

    const trackPath = () => {
        let allEntity: any = [];
        // console.log(`props: `, props)
        props.segregatedData.map((e: any) => {
            if (e.cesiumEntity3D) allEntity.push(...e.cesiumEntity3D);
        })
        //  console.log(allEntity);
        props.viewer3D.flyTo(allEntity);// flyTo method is used to focus on entity/entities
        
        console.log(props?.viewer2D?.entityCollection?._entities?._array);
        props.viewer3D.flyTo(props?.viewer2D?.entityCollection?._entities?._array);// flyTo method is used to focus on entity/entities

    }


    const dragStart = (event: any) => {
        event.dataTransfer.setData("text/plain", event.target.id);
        event.dataTransfer.effectAllowed = 'move';
    }

    const drop = (event: any) => {
        event.preventDefault();
        let data = event.dataTransfer.getData("text/plain");
        //event.target.appendChild(document.getElementById(data));
        setRenderAgain(!renderAgain);
        const currentDragElementOffset = event.clientY

        const items = event.currentTarget.children

        if (items.length === 0) {
            event.currentTarget.appendChild(document.getElementById(data))
            // console.log(event.currentTarget.id)
            return
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            const {
                top,
                height
            } = item.getBoundingClientRect()
            // find the offset left from the center of the item
            const currentElementTopOffset = top + height / 2;

            if (currentDragElementOffset < currentElementTopOffset) {
                event.currentTarget.insertBefore(document.getElementById(data), item.nextSibling)
                // console.log(event.currentTarget.id)
                return
            }

            // found no insertion point, that means item was dragged to the end of the list
            if (i === items.length - 1 && currentDragElementOffset > currentElementTopOffset) {
                event.currentTarget.appendChild(document.getElementById(data))
                // console.log(event.currentTarget.id)
            }
        }
    }
    const allowDrop = (event: any) => {
        event.preventDefault();
    }

    const drgOver = (event: any) => {
        event.preventDefault();
        //console.log(event.currentTarget.parentNode.id)
    }

    const resetMainPanel = () => {
        let mainPanel = document.getElementById("mainPanel")
        let dragContainer = document.getElementById("dragContainer")

        let graphs: any = mainPanel?.querySelectorAll('.graph')
        for (let i = 0; i < graphs.length; i++) {
            if (graphs[i].id) {
                let node1: any = document.getElementById(graphs[i].id)
                dragContainer?.appendChild(node1)
            }
        }
    }

    useEffect(() => {

        window.addEventListener('keydown', keyBoardDownEvents);
        window.addEventListener('keyup', keyBoardUpEvents);

        // cleanup this component
        return () => {
            window.removeEventListener('keydown', keyBoardDownEvents);
            window.addEventListener('keyup', keyBoardUpEvents);
        };
    }, []);


    const altMSLArray = useMemo(() => {
        return csvJSonArray.map((item: any) => item.AltMSL);
    }, [csvJSonArray]);

    const iasArray = useMemo(() => {
        return csvJSonArray.map(item => item.IAS);
    }, [csvJSonArray]);

    const lclTimeArray = useMemo(() => {
        return parseDateTimeArray(csvJSonArray);
    }, [csvJSonArray]);


    // creating a copy of segregatedData to export to other components
    segregatedDataCopy = props.segregatedData;

    useEffect(() => {
        // console.log(`useEffect Ran`);
        tailNumber = currentCsvFileInfo?.tailNumber;
        startTime = currentCsvFileInfo?.startTimestamp;
    }, [props.tailNumber])

    useEffect(() => {
        AllPhasesChecked() //to make sure all the phases are checked once the user switches log file
    }, [props.segregatedData])

    const initialState = {
        isOpen: false,
        isScoreDetailsModalOpen: false,
        scoreCardBgColor: '#fff',
    };

    const [approaches, setApproaches] = useState({ ...initialState });
    const [maneuvers, setManeuvers] = useState({ ...initialState });
    const [airborne, setAirborne] = useState({ ...initialState });

    const toggleSection = (sectionState: any, setSectionState: any) => {
        const { isOpen } = sectionState;
        setApproaches({ ...initialState })
        setManeuvers({ ...initialState });
        setAirborne({ ...initialState });

        setSectionState({
            ...sectionState,
            isScoreDetailsModalOpen: !isOpen,
            isOpen: !isOpen,
            scoreCardBgColor: isOpen ? '#fff' : '#e0e0e0',
        });
    };

    const toggleApproaches = () => toggleSection(approaches, setApproaches);
    const toggleManeuvers = () => toggleSection(maneuvers, setManeuvers);
    const toggleAirborne = () => toggleSection(airborne, setAirborne);

    useEffect(() => {
        setApproaches((prevState) => ({
            ...prevState,
            scoreCardBgColor: prevState.isOpen ? '#e0e0e0' : '#fff',
        }));
    }, [approaches.isOpen, approaches.isScoreDetailsModalOpen]);

    useEffect(() => {
        setManeuvers((prevState) => ({
            ...prevState,
            scoreCardBgColor: prevState.isOpen ? '#e0e0e0' : '#fff',
        }));
    }, [maneuvers.isOpen, maneuvers.isScoreDetailsModalOpen]);

    useEffect(() => {
        setAirborne((prevState) => ({
            ...prevState,
            scoreCardBgColor: prevState.isOpen ? '#e0e0e0' : '#fff',
        }));
    }, [airborne.isOpen, airborne.isScoreDetailsModalOpen]);


    //=======================================================================

    let getIsScoreDataAvailable: any,
        tableInstanceApproach,
        tableInstanceLanding,
        tableInstanceFlare,
        tableInstanceTaxi,
        tableInstanceTakeoff,
        tableInstanceClimb,
        tableInstanceCruise,
        tableInstanceDescent,
        getTaxiScore,
        getTakeoffScore,
        getClimbScore,
        getCruiseScore,
        getDescentScore,
        getApproachScore,
        getLandingScore,
        getFlareScore;


    if (companyId !== 4) {
        ({
            getIsScoreDataAvailable,
            tableInstanceApproach,
            tableInstanceLanding,
            tableInstanceFlare,
            tableInstanceTaxi,
            tableInstanceTakeoff,
            tableInstanceClimb,
            tableInstanceCruise,
            tableInstanceDescent,
            getTaxiScore,
            getTakeoffScore,
            getClimbScore,
            getCruiseScore,
            getDescentScore,
            getApproachScore,
            getLandingScore,
            getFlareScore,
        } = ScoreTableInstances());
    } else {
        ({
            getIsScoreDataAvailable,
            tableInstanceApproach,
            tableInstanceLanding,
            tableInstanceFlare,
            tableInstanceTaxi,
            tableInstanceTakeoff,
            tableInstanceClimb,
            tableInstanceCruise,
            tableInstanceDescent,
            getTaxiScore,
            getTakeoffScore,
            getClimbScore,
            getCruiseScore,
            getDescentScore,
            getApproachScore,
            getLandingScore,
            getFlareScore,
        } = ScoreTableInstancesChimes());
    }

    // console.log(`props.isLogCrossCountry: `, props.isLogCrossCountry);

    // const [isScoreDataAvailable, setIsScoreDataAvailable] = useState(true);

    const isScoreDataAvailableRef = useRef(true);


    useEffect(() => {
        // setIsScoreDataAvailable(() => getIsScoreDataAvailable());
        isScoreDataAvailableRef.current = getIsScoreDataAvailable();
    }, []);

    const rowClick = (rowid: any, data: any) => { }

    const [gotoPhase, setGotoPhase] = useState<number>();

    const onClickGotoPhase = (val: any) => {
        // console.log(`val: `, val)
        const firstTimeStamp = GetJulianDateFromTimeStamp(props?.segregatedData[0]?.data[0]);
        const gotoTimeStamp = GetJulianDateFromTimeStamp(val.data[0]);
        const diffSeconds = JulianDate.secondsDifference(gotoTimeStamp, firstTimeStamp);
        setGotoPhase(diffSeconds);
    };

    const [leftSectionWidth, setLeftSectionWidth] = useState(0);

    useEffect(() => {
        const updateLeftSectionWidth = () => {
            const leftSection = document.getElementById('player-left-section');
            // console.log(leftSection);
            if (leftSection) {
                setLeftSectionWidth(() => (leftSection.offsetWidth + 48));
            }
        };

        // Set the initial width
        updateLeftSectionWidth();

        // Add event listener to update width on window resize
        window.addEventListener('resize', updateLeftSectionWidth);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateLeftSectionWidth);
        };
    }, []);

    // useEffect(() => {
    //     console.log(`segregatedData: `, props.segregatedData)
    // }, [props.segregatedData]);

    // console.log(`segregatedData: `, props.segregatedData.filter((item:any) => item.label === 'Takeoff'));

    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const handleToggle = (key: string) => {
        if (activeKeys.includes(key)) {
            // If the key is already in the activeKeys array, remove it (collapse the section)
            setActiveKeys(activeKeys.filter(activeKey => activeKey !== key));
        } else {
            // Otherwise, add the key to the activeKeys array (expand the section)
            setActiveKeys([...activeKeys, key]);
        }
    };

    // const getParentClassNames = (elementId:string) => {
    //     const element = document.getElementById(elementId);
    //     if (!element) {
    //       console.error('Element not found');
    //       return [];
    //     }

    //     let parent = element.parentElement;
    //     const classNames = [];

    //     while (parent) {
    //       if (parent.className) {
    //         classNames.push(parent.className);
    //       }
    //       parent = parent.parentElement;
    //     }

    //     console.log(`-------------`);
    //     console.log(`-------------`);
    //     return classNames;
    //   }

    //   setTimeout(() => {
    //     const parentClassNames = getParentClassNames("uniqueId");
    //     console.log(parentClassNames);
    //   }, 2000);


    const toRadians = (degrees: any) => {
        return degrees * (Math.PI / 180);
    }

    const haversineDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
        const R = 6371; //radius of the earth in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceInKilometers = R * c;

        const distanceInMeters = distanceInKilometers * 1000;
        return distanceInMeters;
    }

    const calculateTotalDistanceFromLatLong = (dataArray: any) => {
        let totalDistance = 0;

        for (let i = 0; i < dataArray.length - 1; i++) {
            const point1 = dataArray[i];
            const point2 = dataArray[i + 1];

            const distance = haversineDistance(
                parseFloat(point1.Latitude),
                parseFloat(point1.Longitude),
                parseFloat(point2.Latitude),
                parseFloat(point2.Longitude)
            );

            totalDistance += distance;
        }

        return totalDistance; // in meters
    }

    const calculateRateChange = (dataArray: any, property: any) => {
        if (dataArray.length < 2) return 0; // If there's less than 2 points, rate change cannot be calculated

        let totalRateChange = 0;

        for (let i = 1; i < dataArray.length; i++) {
            const previousItem = dataArray[i - 1];
            const currentItem = dataArray[i];

            const valueDifference = parseFloat(currentItem[property]) - parseFloat(previousItem[property]);

            // Calculate the time difference in seconds
            const previousTime = new Date(previousItem.DateTimeUtc).getTime();
            const currentTime = new Date(currentItem.DateTimeUtc).getTime();
            const timeDifferenceInSeconds = (currentTime - previousTime) / 1000; // Convert milliseconds to seconds

            if (timeDifferenceInSeconds) {
                const rateChange = valueDifference / timeDifferenceInSeconds;
                totalRateChange += rateChange;
            }
        }

        // console.log(`totalRateChange ${property}: `, totalRateChange);

        const avgRateChange = totalRateChange / (dataArray.length - 1);
        return avgRateChange;
    }


    const calculateBearing = (lat1: any, lon1: any, lat2: any, lon2: any) => {
        // Convert latitudes and longitudes from degrees to radians
        const toRadians = (degree: any) => degree * (Math.PI / 180);

        const lat1Rad = toRadians(lat1);
        const lat2Rad = toRadians(lat2);
        const deltaLonRad = toRadians(lon2 - lon1);

        // Calculate bearing
        const y = Math.sin(deltaLonRad) * Math.cos(lat2Rad);
        const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
            Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLonRad);
        let initialBearing = Math.atan2(y, x);

        // Convert bearing from radians to degrees
        initialBearing = initialBearing * (180 / Math.PI);

        // Normalize the bearing to be within the range 0° to 360°
        const bearing = (initialBearing + 360) % 360;

        return bearing;
    }

    const getBearingAverage = (dataArray: any) => {
        if (dataArray.length < 2) return 0;

        // const bearings:number[] = [];
        let bearingSum = 0, totalNonZeroValues = 0;

        for (let i = 0; i < dataArray.length - 1; i++) {
            const currentPoint = dataArray[i];
            const nextPoint = dataArray[i + 1];

            const lat1 = parseFloat(currentPoint.Latitude);
            const lon1 = parseFloat(currentPoint.Longitude);
            const lat2 = parseFloat(nextPoint.Latitude);
            const lon2 = parseFloat(nextPoint.Longitude);

            const bearing = calculateBearing(lat1, lon1, lat2, lon2);
            if (bearing !== 0) {
                totalNonZeroValues++;
            }
            bearingSum += bearing;
            // bearings.push(bearing);
        }

        // return (bearingSum / dataArray.length);
        return (bearingSum / totalNonZeroValues);
    }

    function timeToSeconds(timeString: string) {
        // Split the time string into hours, minutes, and seconds
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        // Convert hours, minutes, and seconds to total seconds
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;

        return totalSeconds;
    }

    useEffect(() => {
        const mainPhasesAccordionElement = document.getElementById('flight-phases-main-accordion');
        if (mainPhasesAccordionElement) {
            const accordionButton = mainPhasesAccordionElement.querySelector('button');
            if (accordionButton?.classList.contains('collapsed')) {
                accordionButton?.click();
            }
        }
    }, [props.segregatedData]);


    const isReportBeingGeneratedRef = useRef<boolean>(false);

    const generateReport = async () => {
        // console.log(1: , Date.now());
        isReportBeingGeneratedRef.current = true;
        setIsLoading(true);

        const graphElement = document.getElementById('plotly-graph');

        await new Promise(resolve => setTimeout(resolve, 100));

        const doc = new jsPDF('portrait', 'mm', 'a4');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const borderPadding = 3;

        // Add the border to the first page
        doc.setLineWidth(1);
        doc.rect(borderPadding, borderPadding, pageWidth - borderPadding * 2, pageHeight - borderPadding * 2);

        const logoX = 5;
        const logoY = 5;
        const desiredLogoWidth = 30;
        const originalLogoWidth = 330;
        const originalLogoHeight = 33;

        const aspectRatio = originalLogoHeight / originalLogoWidth;
        const logoHeight = desiredLogoWidth * aspectRatio;

        doc.addImage(aerxlabsLogo, 'PNG', logoX, logoY, desiredLogoWidth, logoHeight);

        // doc.addPage();
        doc.rect(borderPadding, borderPadding, pageWidth - borderPadding * 2, pageHeight - borderPadding * 2);
        doc.addImage(aerxlabsLogo, 'PNG', logoX, logoY, desiredLogoWidth, logoHeight);

        // Flight Log Information
        const headerText = "AerX Flight360 Log Analysis Report";
        const pilotInfo = `Pilot Name : ${currentCsvFileInfo?.pilotName}
        Departure Time : ${getTime(currentCsvFileInfo.startTimestamp)}
        Arrival Time : ${getTime(currentCsvFileInfo.endTimestamp)}
        Flight Duration : ${props.analysisData?.airTime}
        Exercise : ${(currentCsvFileInfo?.exercise) ? (currentCsvFileInfo?.exercise) : '--'}
        Landings : ${props.segregatedData.filter((item: any) => item.labelId === 6).length}`

        // Set font size and style for the header
        doc.setFontSize(30);
        doc.setFont('helvetica', 'bold');
        doc.text(headerText, pageWidth / 2, 80, { align: 'center' });

        // Set font size and style for the flight log details
        doc.setFontSize(22);
        doc.setFont('helvetica', 'normal');
        const startY = 120;
        const lineSpacing = 16;
        const textX = 15;

        // Split the pilotInfo string into an array of lines and print each line
        const lines = pilotInfo.split('\n').map(item => item.trim());
        // console.log(lines: , lines);
        lines.forEach((line, index) => {
            doc.text(line, textX, startY + index * lineSpacing);
        });

        // Move to the next page
        doc.addPage();
        doc.setLineWidth(1);
        doc.rect(borderPadding, borderPadding, pageWidth - borderPadding * 2, pageHeight - borderPadding * 2);
        doc.addImage(aerxlabsLogo, 'PNG', logoX, logoY, desiredLogoWidth, logoHeight);

        // Add circuit and phase label as header
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        doc.text(`Flight Elevation Profile`, pageWidth / 2, 40, { align: 'center' });
        // console.log(2: , Date.now());

        let graphsToRemove:any = {};

        
        // console.log (`companyId: `, companyId)
        
        if (companyId === 4) {
            
            // console.log(`Inside IF Statement`);

            // no graphs from descent and approach - removed later from variable phasesToInclude
            graphsToRemove = {"Takeoff": ["VSpd vs Distance", "IAS vs Distance"],
                "Climb": ["IAS vs VSpd vs Time"],
                "Cruise": ["IAS vs GndSpd vs Time"],
                // "Descent": ["Alt vs Time", "VSpd vs Time", "IAS vs GndSpd vs Time"],
                // "Approach": ["Alt vs Time", "IAS vs GndSpd vs Time"],
                "Landing": ["IAS vs Distance", "VSpd vs Pitch vs Time"]
            }
        }

        // console.log(`graphsToRemove: `, graphsToRemove);

        if (graphElement) {

            const graphImg = await Plotly.toImage(graphElement, { 
                format: 'png',
                width: 500,
                height: 400,
                scale: 1.2,
              });
              
            const imgWidth = pageWidth - borderPadding * 4;
            
            const imgHeight = (500 * imgWidth) / 700;

            // Add the graph image to the PDF on the second page
            doc.addImage(graphImg, 'PNG', borderPadding * 2, 50, imgWidth, imgHeight);

            // Loop over each circuit in segregatedData to add graphs
            for (const circuitData of props.segregatedData) {
                
                // console.log(`circuitData: `, circuitData);

                const phasesToInclude = (companyId === 4) ? (circuitData.labelId < 9 && circuitData.labelId !== 4 && circuitData.labelId !== 5) : (circuitData.labelId < 9)

                if (phasesToInclude) {
                    // console.log(`circuitData: `, circuitData);
                    const { circuitNo, graphList, label } = circuitData;

                    const removeList = graphsToRemove[label] || [];
                    
                    // console.log(`removeList: `, removeList);
                    
                    const updatedGraphList = graphList.filter((graph:any) => !removeList.includes(graph));

                    // console.log(`${label} : ${updatedGraphList}`)


                    // Add a new page for each circuit
                    doc.addPage();
                    doc.setLineWidth(1);
                    doc.rect(borderPadding, borderPadding, pageWidth - borderPadding * 2, pageHeight - borderPadding * 2);
                    doc.addImage(aerxlabsLogo, 'PNG', logoX, logoY, desiredLogoWidth, logoHeight);

                    // Add circuit and phase label as header
                    doc.setFontSize(20);
                    doc.setFont('helvetica', 'bold');
                    doc.text(`Circuit - ${circuitNo}`, pageWidth / 2, 50, { align: 'center' });
                    doc.setFontSize(18);
                    doc.text(`Phase: ${label}`, pageWidth / 2, 70, { align: 'center' });

                    // Set initial Y-position for the graphs under each phase header
                    let currentY = 90;

                    // Loop through each graph in graphList
                    for (const graphName of updatedGraphList) {
                        
                        const graphElement = document.getElementById(`${circuitNo}_${label}_${graphName.toLowerCase().replace(/\s+/g, '_')}`);
                        if (graphElement) {
                            try {
                                const imgData = await Plotly.toImage(graphElement, { 
                                    format: 'png', 
                                    width: 500, 
                                    height: 400,
                                    scale: 1.2,
                                  });

                                const imgWidth = pageWidth - borderPadding * 4;
                                const imgHeight = (500 * imgWidth) / 700;

                                // Check if there’s enough space on the page, else add a new page
                                if (currentY + imgHeight > pageHeight - borderPadding * 2) {
                                    doc.addPage();
                                    doc.setLineWidth(1);
                                    doc.rect(borderPadding, borderPadding, pageWidth - borderPadding * 2, pageHeight - borderPadding * 2);
                                    doc.addImage(aerxlabsLogo, 'PNG', logoX, logoY, desiredLogoWidth, logoHeight);
                                    doc.setFontSize(20);
                                    doc.text(`Circuit - ${circuitNo}`, pageWidth / 2, 50, { align: 'center' });
                                    doc.setFontSize(18);
                                    doc.text(`Phase: ${label}`, pageWidth / 2, 70, { align: 'center' });

                                    // Reset Y-position after a new page
                                    currentY = 90;
                                }

                                 // Add graph name above the image
                                doc.setFontSize(14);
                                doc.setFont('helvetica', 'bold');
                                doc.text(`Graph: ${graphName}`, borderPadding * 2, currentY);
                                currentY += 10;  // Adjust position for space between title and graph

                                // Add the graph image to the current position
                                doc.addImage(imgData, 'PNG', borderPadding * 2, currentY, imgWidth, imgHeight);

                                // Update Y-coordinate for the next graph
                                currentY += imgHeight + 10;  // Adding spacing between graphs
                            } catch (error:any) {
                                console.error(`Failed to add image for Circuit: ${circuitNo}, Phase: ${label}, Graph: ${graphName}. Error: ${error.message}`);
                                continue;
                            }
                        } else {
                            console.warn(`Graph element not found: ${graphName}`);
                        }
                    }
                }
            }

        } else {
            console.warn("Graph element not found.");
        }

        doc.save(`Report_${currentCsvFileInfo?.pilotName}_${getTime(currentCsvFileInfo.startTimestamp)}.pdf`);

        isReportBeingGeneratedRef.current = false;
        setIsLoading(false);
    };



    return (
        <>
            {isLoading && <div className="spinnerWrap"> <LoadSpinnerBig /></div>}
            <div className="debrief-player h-100 d-flex">
                <div className={(!showLeftSection) ? "left-section left-section-closed" : "left-section"} id={"player-left-section"}>
                    {!approaches.isScoreDetailsModalOpen && !maneuvers.isScoreDetailsModalOpen && !airborne.isScoreDetailsModalOpen &&
                        <button className="btn-toggle-panel" onClick={toggleLeftSection}></button>}
                    <div className="left-section-content h-100 d-flex flex-column overflow-auto">
                        <div className="logs-panel">
                            <h3>
                                {props.callFrom} / Debrief Details
                            </h3>
                            <div className="logs-change-panel row">
                                <button onClick={() => closePlayer()}>Logs</button>
                                {/* <div className="col-8">
                                    <button className="btn-prev-log" onClick={playPrevDebrief}></button>
                                    {props.currentCsvRowId + 1} of {props.debriefFileList.length}
                                    <button className="btn-next-log" onClick={playNextDebrief}></button>
                                </div> */}
                            </div>
                        </div>

                        <div className="log-details">
                            <div className="log-general-details">
                                <p className="date">{changeDateFormat(currentCsvFileInfo?.startTimestamp)}</p>
                                <p className="pilot">{currentCsvFileInfo?.pilotName}, {currentCsvFileInfo?.tailNumber}</p>
                            </div>
                            <div className="logs-phases">
                                <Accordion>
                                    <Accordion.Header className="main-heading">
                                        GENERIC DETAILS
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-generic-details p-2">
                                        <div className="d-flex justify-content-between details">
                                            <div className="">
                                                <p className="airport">{props.currentCsvFileInfo?.takeoff}</p>
                                                <p className="runway">{"RWY " + props.currentCsvFileInfo?.takeoffRunway}</p>
                                            </div>
                                            <div className="flex-grow-1 space-line m-2"></div>
                                            <div className="">
                                                <p className="airport">{props.currentCsvFileInfo?.landing}</p>
                                                <p className="runway">{"RWY " + props.currentCsvFileInfo?.landingRunway}</p>
                                            </div>
                                        </div>
                                        <Accordion className="extra-details mt-2">
                                            <Accordion.Header>
                                                <div className="d-flex flex-column">
                                                    <div className="airtime mb-2"><strong>Air Time : {props.analysisData?.airTime}</strong></div>
                                                    <div className="distance"><strong>Distance : {props.currentCsvFileInfo?.distance}</strong> NM</div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="p-1">
                                                <div className="rounded m-1 p-1 bg-white info-box">
                                                    <p className="mt-1 mb-1">No. Of T/O’s : {props?.analysisData?.noOfTakeOffs ?? '--'}</p>
                                                    <p className="mt-1 mb-1">Takeoff Time : {getTime(currentCsvFileInfo.startTimestamp)}</p>
                                                    <p className="mt-1 mb-1">Landing Time : {getTime(currentCsvFileInfo.endTimestamp)}</p>
                                                    <p className="mt-1 mb-1">Co-Pilot : {props.currentCsvFileInfo?.secondaryPilotName}</p>
                                                    <p className="mt-1 mb-1">Engine Time : {props.currentCsvFileInfo?.airTime}</p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion>
                            </div>

                            {/*   {isScoreDataAvailable && (props.status === 3 ) &&  */}
                            {isScoreDataAvailableRef.current && (props.currentCsvFileInfo?.avgOverallScore?.props?.children?.props?.children !== 0) &&
                                <div className="logs-phases">
                                    <Accordion>
                                        <Accordion.Header className="main-heading">
                                            TOTAL SCORE
                                        </Accordion.Header>
                                        <Accordion.Body className="accordion-generic-details p-2">
                                            <Accordion className="extra-details mt-2">
                                                <Accordion.Header>
                                                    <div className="container">
                                                        <div className="row">
                                                            {/* <div className="col" style={{ width: '20%', height: '20%' }}> */}
                                                            <div className="col">
                                                                <GaugeGraphPlot value={(props.currentCsvFileInfo?.avgOverallScore?.props?.children?.props?.children)} fillColor={'#477afe'} />
                                                            </div>
                                                            <div className="col">
                                                                <div className="row score-text mb-2"> {(props.currentCsvFileInfo?.avgOverallScore?.props?.children?.props?.children)} </div>
                                                                <div className="row total-score-accordion-text">{props.currentCsvFileInfo?.excercise}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>

                                                <Accordion.Body>
                                                    <Card className="total-score-card">
                                                        {/* <Card.Header className="total-score-card" style={{ backgroundColor: maneuversScoreCardBgColor }}> */}
                                                        <Card.Header className="total-score-card" style={{ backgroundColor: maneuvers.scoreCardBgColor }}>
                                                            <div className="container d-flex align-items-center">
                                                                <div className="container-fluid ml-auto">
                                                                    <div><strong>Maneuvers</strong></div>
                                                                    <div className="total-score-accordion-text">2 Phases</div>
                                                                </div>
                                                                <div className="badge-pill badge mx-2" style={{ backgroundColor: pillBgColor((Number(getTaxiScore()) + Number(getTakeoffScore())) / 2) }}>
                                                                    <div className="badge-text" >{((Number(getTaxiScore()) + Number(getTakeoffScore())) / 2).toFixed(1)}</div>
                                                                </div>
                                                                <div>
                                                                    <button className="custom-button" onClick={toggleManeuvers}></button>
                                                                </div>
                                                            </div>
                                                        </Card.Header>

                                                        {/* Modal component */}
                                                        {/* {isManeuversScoreDetailsModalOpen && ( */}
                                                        {maneuvers.isScoreDetailsModalOpen && (
                                                            // <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px`, width: `calc(100% - ${leftSectionWidth}px)` }}>
                                                            <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px` }}>
                                                                <div className="score-modal-content">
                                                                    <div className="score-modal-header">
                                                                        <div className="container-fluid d-flex justify-content-end">
                                                                            <button type="button" className="btn-close" onClick={toggleManeuvers}></button>
                                                                        </div>
                                                                        <span className="score-modal-title"><strong>Maneuvers</strong></span>
                                                                        <br />
                                                                        <span className="total-score-accordion-text">2 Phases</span>
                                                                        {/* <span className="total-score-accordion-text mx-4">Vref : <u style={{ color: 'blue' }}>65 kts</u></span> */}
                                                                    </div>

                                                                    <div className="score-modal-body">
                                                                        <Accordion>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getTaxiScore()} fillColor={pillBgColor(getTaxiScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Taxi: {props.currentCsvFileInfo.landing_runway}
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* ?? ??   ??       ?? */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getTaxiScore()) }}>
                                                                                                <div className="badge-text" >{getTaxiScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="phases-score-table">
                                                                                <StickyTable tableInstance={tableInstanceTaxi} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                        <Accordion style={{ paddingBottom: '90px' }}>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getTakeoffScore()} fillColor={pillBgColor(getTakeoffScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Takeoff: {props.currentCsvFileInfo.landing_runway}
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* ?? ??    ??  ?? */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getTakeoffScore()) }}>
                                                                                                <div className="badge-text" >{getTakeoffScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="pb-4">
                                                                                <StickyTable tableInstance={tableInstanceTakeoff} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </Card>

                                                    <Card className="total-score-card mt-2">
                                                        {/* <Card.Header className="total-score-card" style={{ backgroundColor: airborneScoreCardBgColor }}> */}
                                                        <Card.Header className="total-score-card" style={{ backgroundColor: airborne.scoreCardBgColor }}>
                                                            <div className="container d-flex align-items-center">
                                                                <div className="container-fluid ml-auto">
                                                                    <div><strong>Airborne</strong></div>
                                                                    {(companyId === 4) && (
                                                                        <div className="total-score-accordion-text">{(props.isLogCrossCountry) ? '3 Phases' : '2 Phases'}</div>
                                                                    )}

                                                                    {(companyId !== 4) && (
                                                                        <div className="total-score-accordion-text">3 Phases</div>
                                                                    )}


                                                                </div>

                                                                {(companyId !== 4) && (
                                                                    <div className="badge-pill badge mx-2" style={{ backgroundColor: pillBgColor((Number(getClimbScore()) + Number(getCruiseScore()) + Number(getDescentScore())) / 3) }}>
                                                                        <div className="badge-text" >{((Number(getClimbScore()) + Number(getCruiseScore()) + Number(getDescentScore())) / 3).toFixed(1)}</div>
                                                                    </div>
                                                                )}

                                                                {(companyId === 4) && (props.isLogCrossCountry) && (
                                                                    <div className="badge-pill badge mx-2" style={{ backgroundColor: pillBgColor((Number(getClimbScore()) + Number(getCruiseScore()) + Number(getDescentScore())) / 3) }}>
                                                                        <div className="badge-text" >{((Number(getClimbScore()) + Number(getCruiseScore()) + Number(getDescentScore())) / 3).toFixed(1)}</div>
                                                                    </div>
                                                                )}
                                                                {(companyId === 4) && (!props.isLogCrossCountry) && (
                                                                    <div className="badge-pill badge mx-2" style={{ backgroundColor: pillBgColor((Number(getClimbScore()) + Number(getDescentScore())) / 2) }}>
                                                                        <div className="badge-text" >{((Number(getClimbScore()) + Number(getDescentScore())) / 2).toFixed(1)}</div>
                                                                    </div>
                                                                )}

                                                                <div>
                                                                    <button className="custom-button" onClick={toggleAirborne}></button>
                                                                </div>
                                                            </div>
                                                        </Card.Header>

                                                        {/* Modal component */}
                                                        {/* {isAirborneScoreDetailsModalOpen && ( */}
                                                        {airborne.isScoreDetailsModalOpen && (
                                                            // <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px`, width: `calc(100% - ${leftSectionWidth}px)` }}>
                                                            <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px` }}>
                                                                <div className="score-modal-content">
                                                                    <div className="score-modal-header">
                                                                        <div className="container-fluid d-flex justify-content-end">
                                                                            <button type="button" className="btn-close" onClick={toggleAirborne}></button>
                                                                        </div>
                                                                        <span className="score-modal-title"><strong>Airborne</strong></span>
                                                                        <br />
                                                                        {(companyId === 4) && (
                                                                            <span className="total-score-accordion-text">{(props.isLogCrossCountry) ? '3 Phases' : '2 Phases'}</span>
                                                                        )}
                                                                        {(companyId !== 4) && (
                                                                            <span className="total-score-accordion-text">3 Phases</span>
                                                                        )}

                                                                        {/* <span className="total-score-accordion-text mx-4">Vref : <u style={{ color: 'blue' }}>65 kts</u></span> */}
                                                                    </div>

                                                                    {/* <div className="score-modal-body"> */}
                                                                    {/* <div className="score-modal-body" style={{paddingBottom: '90px'}}> */}
                                                                    <div className="score-modal-body" style={{ paddingBottom: '140px' }}>
                                                                        <Accordion>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getClimbScore()} fillColor={pillBgColor(getClimbScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Climb:
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* ?? ??   ??       ?? */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getClimbScore()) }}>
                                                                                                <div className="badge-text" >{getClimbScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="phases-score-table">
                                                                                <StickyTable tableInstance={tableInstanceClimb} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>

                                                                        {(companyId === 4) && (props.isLogCrossCountry) && (
                                                                            <Accordion>
                                                                                <Accordion.Header>
                                                                                    <div className="container">
                                                                                        <div className="row d-flex align-items-center">
                                                                                            <div className="col-2">
                                                                                                <GaugeGraphPlot value={getCruiseScore()} fillColor={pillBgColor(getCruiseScore())} />
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                    Cruise:
                                                                                                </div>
                                                                                                <div className="row total-score-accordion-text">
                                                                                                    {/* ?? ??    ??  ?? */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                                <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getCruiseScore()) }}>
                                                                                                    <div className="badge-text" >{getCruiseScore()}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className="pb-4">
                                                                                    <StickyTable tableInstance={tableInstanceCruise} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                                </Accordion.Body>
                                                                            </Accordion>
                                                                        )}
                                                                        {(companyId !== 4) && (
                                                                            <Accordion>
                                                                                <Accordion.Header>
                                                                                    <div className="container">
                                                                                        <div className="row d-flex align-items-center">
                                                                                            <div className="col-2">
                                                                                                <GaugeGraphPlot value={getCruiseScore()} fillColor={pillBgColor(getCruiseScore())} />
                                                                                            </div>
                                                                                            <div className="col-12">
                                                                                                <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                    Cruise:
                                                                                                </div>
                                                                                                <div className="row total-score-accordion-text">
                                                                                                    {/* ?? ??    ??  ?? */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                                <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getCruiseScore()) }}>
                                                                                                    <div className="badge-text" >{getCruiseScore()}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Accordion.Header>
                                                                                <Accordion.Body className="pb-4">
                                                                                    <StickyTable tableInstance={tableInstanceCruise} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                                </Accordion.Body>
                                                                            </Accordion>
                                                                        )}


                                                                        <Accordion style={{ paddingBottom: '90px' }}>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getDescentScore()} fillColor={pillBgColor(getDescentScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Descent:
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* ??     ??     ??  ?? */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getDescentScore()) }}>
                                                                                                <div className="badge-text" >{getDescentScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="pb-4">
                                                                                <StickyTable tableInstance={tableInstanceDescent} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </Card>

                                                    <Card className="total-score-card mt-2">
                                                        {/* <Card.Header className="total-score-card" style={{ backgroundColor: approachScoreCardBgColor }}> */}
                                                        <Card.Header className="total-score-card" style={{ backgroundColor: approaches.scoreCardBgColor }}>
                                                            <div className="container d-flex align-items-center">
                                                                <div className="container-fluid ml-auto">
                                                                    <div><strong>Approaches</strong></div>
                                                                    <div className="total-score-accordion-text">3 Phases</div>
                                                                </div>
                                                                <div className="badge-pill badge mx-2" style={{ backgroundColor: pillBgColor((Number(getApproachScore()) + Number(getLandingScore()) + Number(getFlareScore())) / 3) }}>
                                                                    <div className="badge-text" >{((Number(getApproachScore()) + Number(getLandingScore()) + Number(getFlareScore())) / 3).toFixed(1)}</div>
                                                                </div>
                                                                <div>
                                                                    <button className="custom-button" onClick={toggleApproaches}></button>
                                                                </div>
                                                            </div>
                                                        </Card.Header>

                                                        {/* Modal component */}
                                                        {/* {isApproachesScoreDetailsModalOpen && ( */}
                                                        {approaches.isScoreDetailsModalOpen && (
                                                            // <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px`, width: `calc(100% - ${leftSectionWidth}px)` }}>
                                                            <div className="score-modal-overlay" style={{ marginLeft: `${leftSectionWidth}px` }}>
                                                                <div className="score-modal-content">
                                                                    <div className="score-modal-header">
                                                                        <div className="container-fluid d-flex justify-content-end">
                                                                            <button type="button" className="btn-close" onClick={toggleApproaches}></button>
                                                                        </div>
                                                                        <span className="score-modal-title"><strong>Approaches</strong></span>
                                                                        <br />
                                                                        <span className="total-score-accordion-text">2 Phases</span>
                                                                        {/* <span className="total-score-accordion-text mx-4">Vref : <u style={{ color: 'blue' }}>65 kts</u></span> */}
                                                                    </div>

                                                                    <div className="score-modal-body">
                                                                        <Accordion>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getApproachScore()} fillColor={pillBgColor(getApproachScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Approach: {props.currentCsvFileInfo.landing_runway}
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* 02:56 PM (2m 16s) -  Avg. Slope : 2.7°,  2.7NM */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getApproachScore()) }}>
                                                                                                <div className="badge-text" >{getApproachScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="phases-score-table">
                                                                                <StickyTable tableInstance={tableInstanceApproach} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                        <Accordion>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getLandingScore()} fillColor={pillBgColor(getLandingScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Landing: {props.currentCsvFileInfo.landing_runway}
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* 02:57 PM (1m 1s) -  Slope = 3.4°,  Touch Down=4.7',  1.1NM */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getLandingScore()) }}>
                                                                                                <div className="badge-text" >{getLandingScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="pb-4">
                                                                                <StickyTable tableInstance={tableInstanceLanding} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                        <Accordion style={{ paddingBottom: '110px' }}>
                                                                            <Accordion.Header>
                                                                                <div className="container">
                                                                                    <div className="row d-flex align-items-center">
                                                                                        <div className="col-2">
                                                                                            <GaugeGraphPlot value={getFlareScore()} fillColor={pillBgColor(getFlareScore())} />
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="row" style={{ fontWeight: 'bold' }}>
                                                                                                Flare:
                                                                                            </div>
                                                                                            <div className="row total-score-accordion-text">
                                                                                                {/* 02:57 PM (1m 1s) -  Slope = 3.4°,  Touch Down=4.7',  1.1NM */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-1 d-flex align-items-center justify-content-end">
                                                                                            <div className="badge-pill badge" style={{ backgroundColor: pillBgColor(getFlareScore()) }}>
                                                                                                <div className="badge-text" >{getFlareScore()}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body className="pb-4">
                                                                                <StickyTable tableInstance={tableInstanceFlare} rowClick={rowClick} showPaginationDiv={false}></StickyTable>
                                                                            </Accordion.Body>
                                                                        </Accordion>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Card>

                                                </Accordion.Body>
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion>
                                </div>}

                            <div className="logs-phases">
                                <Accordion id={'flight-phases-main-accordion'}>
                                    <Accordion.Header className="main-heading">
                                        FLIGHT PHASES
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-phases">
                                        <div className="d-flex align-items-start">
                                            <input type="checkbox" onChange={checkAllPhases} checked={isAllPhasesChecked} />
                                            <Accordion className="flex-grow-1 pt-2 pb-2 entire-phases">
                                                <Accordion.Header className="phases-list-header">
                                                    <div className="phase-name">Entire Flight :  <span>{props.currentCsvFileInfo?.takeoff?.split("(")[0]} - {props.currentCsvFileInfo?.landing?.split("(")[0]}</span>
                                                        <div className="phase-duration">{props.currentCsvFileInfo?.airTime}
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className="phases-list-body" id="entire-flight-accordion">
                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{props.currentCsvFileInfo?.distance} NM</span></div>
                                                </Accordion.Body>
                                            </Accordion>
                                        </div>
                                        {!props.isFileLoading && props.segregatedData.length !== 0 &&
                                            <p className="text-center mt-1 mb-0" style={{ color: `grey` }}>Individual Phases</p>
                                        }

                                        {!props.isFileLoading && props.segregatedData.length === 0 &&
                                            <p className="text-center mt-1 mb-0" style={{ color: `grey` }}>Data Invalid For Phase Detection</p>
                                        }

                                        {/* {props.segregatedData.filter((ele:any) => ele.partitionIndex === null).map((val: any, index: any) => */}


                                        {props.segregatedData.filter((ele: any) => ele.labelId <= 9).map((val: any, index: any) => {

                                            // console.log(`val: `, val);

                                            let avgGroundSpeedTaxi = '-',
                                                totalDistance = '-';
                                            if (val.label === 'Taxi') {
                                                // console.log(`val: `, val);
                                                avgGroundSpeedTaxi = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.GndSpd)) }, 0) / val.data.length)?.toFixed(1);
                                                // console.log(`avgGroundSpeedTaxi of ${val.sequenceId}: `,  avgGroundSpeedTaxi);
                                                totalDistance = calculateTotalDistanceFromLatLong(val.data)?.toFixed(1);
                                                // console.log(`totalDistance of ${val.sequenceId}: `,  totalDistance);
                                            }

                                            let avgPitchTakeoff: number | string = '-',
                                                avgPitchRateChangeTakeoff: number | string = '-',
                                                unstickSpeed: number | string = '-',
                                                minYaw: number | string = 0,
                                                maxYaw: number | string = 0;

                                            if (val.label === 'Takeoff') {
                                                // console.log(`val: `, val);
                                                totalDistance = calculateTotalDistanceFromLatLong(val.data)?.toFixed(1);
                                                avgPitchTakeoff = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Pitch)) }, 0) / val.data.length)?.toFixed(1);
                                                avgPitchRateChangeTakeoff = calculateRateChange(val.data, 'Pitch')?.toFixed(2);

                                                val.subPhaseMapping.forEach((item: any) => {
                                                    const data = props.segregatedData[item];
                                                    if (data.label === 'Lift Off') {
                                                        unstickSpeed = parseFloat(data.data[0].IAS)
                                                    }
                                                });

                                                const avgHeading = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.HDG)) }, 0) / val.data.length);
                                                const headingArray = val.data.map((item: any) => parseFloat(item.HDG));
                                                minYaw = (Math.min(...headingArray) - avgHeading)?.toFixed(1);
                                                maxYaw = (Math.max(...headingArray) - avgHeading)?.toFixed(1);
                                            }

                                            let avgVSpeedClimb = '-',
                                                avgClimbGradient = '-',
                                                avgBankAngleClimb = '-',
                                                avgBankAngleRateChangeClimb = '-',
                                                totalDistanceInNMClimb = '-',
                                                avgIASClimb = '-',
                                                topOfClimb = '-',
                                                avgPitchClimb = '-',
                                                avgPitchRateChangeClimb = '-';
                                            if (val.label === 'Climb') {
                                                // console.log(`val: `, val);
                                                avgVSpeedClimb = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.VSpd)) }, 0) / val.data.length)?.toFixed(1);
                                                const avgTASinFPM = ((val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.TAS)) }, 0) / val.data.length) * 101.3)?.toFixed(1);
                                                avgClimbGradient = (parseFloat(avgVSpeedClimb) / parseFloat(avgTASinFPM) * 100).toFixed(1);
                                                avgBankAngleClimb = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Roll)) }, 0) / val.data.length)?.toFixed(1);
                                                avgBankAngleRateChangeClimb = calculateRateChange(val.data, 'Roll')?.toFixed(2);
                                                totalDistanceInNMClimb = (calculateTotalDistanceFromLatLong(val.data) * 0.000539957)?.toFixed(1);
                                                avgIASClimb = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.IAS)) }, 0) / val.data.length)?.toFixed(1);
                                                topOfClimb = Math.max(...val.data.map((item: any) => item.AltMSL))?.toFixed(1);
                                                avgPitchClimb = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Pitch)) }, 0) / val.data.length)?.toFixed(1);
                                                avgPitchRateChangeClimb = calculateRateChange(val.data, 'Pitch')?.toFixed(2);
                                            }

                                            let totalDistanceInNMCruise = '-',
                                                avgIASCruise = '-',
                                                avgAltMSLCruise = '-';
                                            if (val.label === 'Cruise') {
                                                // console.log(`val: `, val);
                                                totalDistanceInNMCruise = (calculateTotalDistanceFromLatLong(val.data) * 0.000539957)?.toFixed(1);
                                                avgIASCruise = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.IAS)) }, 0) / val.data.length)?.toFixed(1);
                                                avgAltMSLCruise = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.AltMSL)) }, 0) / val.data.length)?.toFixed(1);
                                            }

                                            let avgVSpeedDescent = '-',
                                                avgDescentGradientDescent = '-',
                                                totalDistanceInNMDescent = '-',
                                                topOfDescent = '-',
                                                verticalSpeedAcceleration = '-',
                                                avgIASDescent = '-',
                                                avgPitchDescent = '-',
                                                avgPitchRateChangeDescent = '-';
                                            if (val.label === 'Descent') {
                                                // console.log(`val: `, val);
                                                avgVSpeedDescent = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.VSpd)) }, 0) / val.data.length)?.toFixed(1);
                                                const avgTASinFPM = ((val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.TAS)) }, 0) / val.data.length) * 101.3)?.toFixed(1);
                                                avgDescentGradientDescent = (parseFloat(avgVSpeedDescent) / parseFloat(avgTASinFPM) * 100).toFixed(1);
                                                totalDistanceInNMDescent = (calculateTotalDistanceFromLatLong(val.data) * 0.000539957)?.toFixed(1);
                                                topOfDescent = Math.max(...val.data.map((item: any) => item.AltMSL))?.toFixed(1);
                                                verticalSpeedAcceleration = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.NormAc)) }, 0) / val.data.length)?.toFixed(2);
                                                avgIASDescent = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.IAS)) }, 0) / val.data.length)?.toFixed(1);
                                                avgPitchDescent = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Pitch)) }, 0) / val.data.length)?.toFixed(1);
                                                avgPitchRateChangeDescent = calculateRateChange(val.data, 'Pitch')?.toFixed(2);
                                            }

                                            let avgIASApproach = '-',
                                                avgVSpeedApproach = '-',
                                                avgDescentGradientApproach = '-',
                                                totalDistanceInNMApproach = '-',
                                                avgAltMSLApproach = '-',
                                                avgBankAngleApproach = '-',
                                                avgBankAngleRateChangeApproach = '-';
                                            if (val.label === 'Approach') {
                                                // console.log(`val: `, val);
                                                avgIASApproach = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.IAS)) }, 0) / val.data.length)?.toFixed(1);
                                                avgVSpeedApproach = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.VSpd)) }, 0) / val.data.length)?.toFixed(1);
                                                const avgTASinFPM = ((val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.TAS)) }, 0) / val.data.length) * 101.3)?.toFixed(1);
                                                avgDescentGradientApproach = (parseFloat(avgVSpeedApproach) / parseFloat(avgTASinFPM) * 100).toFixed(1);
                                                totalDistanceInNMApproach = (calculateTotalDistanceFromLatLong(val.data) * 0.000539957)?.toFixed(1);
                                                avgAltMSLApproach = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.AltMSL)) }, 0) / val.data.length)?.toFixed(1);
                                                avgBankAngleApproach = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Roll)) }, 0) / val.data.length)?.toFixed(1);
                                                avgBankAngleRateChangeApproach = calculateRateChange(val.data, 'Roll')?.toFixed(2);

                                            }

                                            let avgIASLanding = '-',
                                                avgPitchLanding = '-',
                                                crabAngleLanding = '-',
                                                flareDurationLanding = '-';
                                            if (val.label === 'Landing') {
                                                // console.log(`val: `, val);
                                                avgIASLanding = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.IAS)) }, 0) / val.data.length)?.toFixed(1);
                                                avgPitchLanding = (val.data.reduce((accumulator: any, item: any) => { return (accumulator + parseFloat(item.Pitch)) }, 0) / val.data.length)?.toFixed(1);
                                                const bearingAverageLanding = getBearingAverage(val.data);
                                                let touchDownHeading = NaN;
                                                val.subPhaseMapping.forEach((item: any) => {
                                                    let segData = props.segregatedData[item];
                                                    if (segData.label === 'Touch Down') {
                                                        touchDownHeading = segData.data[0].HDG;
                                                    } else if (segData.label === 'Flare') {
                                                        flareDurationLanding = timeToSeconds(segData.duration)?.toFixed(1);
                                                    }

                                                });
                                                // console.log(`touchDownHeading: `, touchDownHeading);
                                                // console.log(`bearingAverageLanding: `, bearingAverageLanding);
                                                crabAngleLanding = (touchDownHeading - bearingAverageLanding)?.toFixed(1);
                                            }

                                            return (<div className="d-flex align-items-start" key={val.sequenceId + "_wrapper"}>
                                                <input type="checkbox" id={"phaseSeq_" + val.sequenceId} key={val.sequenceId} onChange={() => IasVsAltVsTimeDataChange(val.sequenceId)} checked={val.isVisible} />


                                                <Accordion key={val.label + val.sequenceId} className={"flex-grow-1 " + val.label + "-phase"}>
                                                    {/* <Accordion key={val.label + val.sequenceId} className={"flex-grow-1 " + val.label + "-phase"} activeKey={activeKeys}> */}


                                                    {/* <Accordion.Item eventKey={val.sequenceId}> */}

                                                    {/* <Accordion.Header key={val.label + val.sequenceId + "_header"} className={"phases-list-header pt-2 pb-2 header"} onClick={() => handleToggle(val.sequenceId)}> */}
                                                    <Accordion.Header key={val.label + val.sequenceId + "_header"} className={"phases-list-header pt-2 pb-2 header d-flex"} onClick={() => handleToggle(val.sequenceId)}>


                                                        {/* <div key={val.sequenceId + "_label"} onClick={(event:any) => {event.stopPropagation(); onClickGotoPhase(val)}}> */}
                                                        <div key={val.sequenceId + "_label"}>
                                                            <span onClick={(event: any) => { event.stopPropagation(); onClickGotoPhase(val) }}><u>{val.label} :  {val.runway !== null ? "RWY " + val.runway : ""} {val.airportCode !== null ? "(" + val.airportCode + ")" : ""}</u></span>
                                                            <span></span>
                                                            <div className="phase-duration" key={val.sequenceId + "_duration"}>{val.duration}
                                                            </div>


                                                            {/* Conditionally rendering additional data here */}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Landing`) && (
                                                                <div className="pt-1">
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgIASLanding} kt</span></div>
                                                                    <div className="crab-angle"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{crabAngleLanding}°</span></div>
                                                                    <div className="pitch"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgPitchLanding}°</span></div>
                                                                    <div className="flare-duration"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{flareDurationLanding} s</span></div>
                                                                </div>
                                                            )}


                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Taxi`) && (
                                                                <div className="pt-1">
                                                                    {/* <div id="uniqueId" className="badge-pill" style={{ backgroundColor: `black`, color: `white` }}>11.1'</div> */}
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgGroundSpeedTaxi} kt</span></div>
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{totalDistance} m</span></div>
                                                                </div>
                                                            )}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Takeoff`) && (
                                                                <div className="pt-1">
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{(Math.abs(val.data[0].distFromRwy) * 1852)?.toFixed(1)} m</span></div>
                                                                    <div className="pitch"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgPitchTakeoff}°, {avgPitchRateChangeTakeoff}°/s</span></div>
                                                                    <div className="unstick"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{unstickSpeed} kt</span></div>
                                                                    <div className="yaw"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{minYaw}° to +{maxYaw}°</span></div>
                                                                </div>
                                                            )}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Climb`) && (
                                                                <div className="pt-1">
                                                                    <div className="vspeed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgVSpeedClimb} fpm</span></div>
                                                                    <div className="climb-gradient"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgClimbGradient} %</span></div>
                                                                    <div className="bank-angle"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgBankAngleClimb}°, {avgBankAngleRateChangeClimb}°/s</span></div>
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{totalDistanceInNMClimb} NM</span></div>
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgIASClimb} kt</span></div>
                                                                    <div className="top-of-climb"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{topOfClimb}'</span></div>
                                                                    <div className="pitch"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgPitchClimb}°, {avgPitchRateChangeClimb}°/s</span></div>
                                                                </div>
                                                            )}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Cruise`) && (
                                                                <div className="pt-1">
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{totalDistanceInNMCruise} NM</span></div>
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgIASCruise} kt</span></div>
                                                                    <div className="phase-altitude"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgAltMSLCruise}'</span></div>

                                                                </div>
                                                            )}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Descent`) && (
                                                                <div className="pt-1">
                                                                    <div className="vspeed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgVSpeedDescent} fpm</span></div>
                                                                    <div className="descent-gradient"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgDescentGradientDescent} %</span></div>
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{totalDistanceInNMDescent} NM</span></div>
                                                                    <div className="top-of-descent"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{topOfDescent}'</span></div>
                                                                    <div className="vertical-speed-acceleration"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{verticalSpeedAcceleration} g</span></div>
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgIASDescent} kt</span></div>
                                                                    <div className="pitch"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgPitchDescent}°, {avgPitchRateChangeDescent}°/s</span></div>
                                                                </div>
                                                            )}

                                                            {activeKeys.includes(val.sequenceId) && val.label === (`Approach`) && (
                                                                <div className="pt-1">
                                                                    <div className="phase-speed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgIASApproach} kt</span></div>
                                                                    <div className="vspeed"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgVSpeedApproach} fpm</span></div>
                                                                    <div className="descent-gradient"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgDescentGradientApproach} %</span></div>
                                                                    <div className="distance"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{totalDistanceInNMApproach} NM</span></div>
                                                                    <div className="phase-altitude"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgAltMSLApproach}'</span></div>
                                                                    <div className="bank-angle"><span style={{ height: `fit-content`, width: `fit-content`, marginRight: `4px` }}>{avgBankAngleApproach}°, {avgBankAngleRateChangeApproach}°/s</span></div>

                                                                </div>
                                                            )}





                                                        </div>



                                                    </Accordion.Header>

                                                    {/* </Accordion.Item> */}


                                                    <Accordion.Body className={"phases-list-body body"} key={val.label + val.sequenceId + "_body"}>



                                                        {/* <button type="button" className="btn btn-outline-dark" onClick={() => onClickGotoPhase(val)}>Goto</button> */}

                                                        {
                                                            //val.subPhaseMapping.length
                                                            val.subPhaseMapping.map((subVal: any, subIndex: any) => (
                                                                props.segregatedData.filter((ele: any) => ele.sequenceId === subVal).map((val1: any) => (
                                                                    <div className="sub-phase-wrap" key={"subphase" + index + subIndex}>
                                                                        <div className="sub-phase">
                                                                            <input className="sub-phase-checkbox" type="checkbox" key={"checkbox" + index + subIndex} onChange={() => IasVsAltVsTimeDataChange(val1.sequenceId)} checked={val1.isVisible} />
                                                                            <div key={"subphaseLabe" + index + subIndex} className="phase-name">{val1.label}</div>
                                                                            <div key={"subphaseDuration" + index + subIndex} className="phase-duration">{val1.duration}</div>
                                                                        </div>
                                                                    </div>
                                                                ))

                                                            ))
                                                        }

                                                        {
                                                            // (val.subPhaseMapping.length === 0 && val.labelId !== 9) &&
                                                            (val.subPhaseMapping.length === 0) &&
                                                            <div className="phase-name d-flex justify-content-center mt-2" style={{ color: `grey`, fontSize: `14px`, fontWeight: `500` }}>
                                                                No Sub-Phase Detected
                                                            </div>
                                                        }

                                                    </Accordion.Body>
                                                    {/* </Accordion.Item> */}
                                                </Accordion>

                                            </div>)
                                        })}


                                    </Accordion.Body>
                                </Accordion>
                            </div>
                        </div>

                    </div>
                </div>

                {/* { (props.status !== 4) && */}
                <div className="right-section border row">
                    <div className={(!showRightSection) ? "side-panel-overlay" : "side-panel-overlay side-panel-overlay-show"} onClick={() => setShowRightSection(!showRightSection)}></div>
                    <div className="main-panel col-lg-10 col-16">
                        <div className="dragContainer" id="mainPanel" onDrop={(e) => drop(e)} onDragOver={allowDrop}>

                            <FlightPath setViewer3D={props.setViewer3D}
                                trackEntity={trackEntity}
                                setViewer2D={props.setViewer2D}
                                ispointerEventDisable={isPointerEventDisable}
                                toggleShowFlightPath3D={changeSceneMode}
                                toggleTrack={toggleTrack}
                                timeChangeData={props.currTimeValue}
                                reset={reset}
                                viewer3D={props.viewer3D}
                                trackPath={trackPath}
                            />

                            <PfdCard currentCsvFileData={props.currentCsvFileData} setViewer={props.setViewer} ispointerEventDisable={isPointerEventDisable}></PfdCard>

                            <IasVsAltVsTimeContainer renderAgain={renderAgain} altMSLArray={altMSLArray} iasArray={iasArray} lclTimeArray={lclTimeArray} viewer={props.viewer} isReportBeingGeneratedRef={isReportBeingGeneratedRef}/>
                        </div>
                        <div className="remark-panel">
                            {/* <div className="container d-flex align-items-center justify-content-end" style={{marginRight: `8px`}}>
                                <div >
                                    <button className="add-remark-button btn btn-primary mr-2" style={{ height: `32px`, width: `fit-content`, right: `10px`}}>Add Remark</button>
                                </div>
                            </div> */}
                            <div>
                                <button className="btn-option" onClick={() => { setShowRightSection(!showRightSection); setRenderAgain(!renderAgain); }}></button>
                            </div>
                        </div>
                    </div>
                    <div className={(!showRightSection) ? "side-panel col-lg-6 col-16" : "side-panel side-panel-show col-md-6 col-16"}>

                        {/* <div className="button-container">
                            <button className="btn-default-layout" onClick={() => { }}>Default Layout</button>
                            <button className="btn-new-view" >New View (N)</button>
                            <button className="btn-option"></button>
                        </div> */}
                        <div className="button-container justify-content-center">
                        <button
                            className="btn btn-outline-primary my-2"
                            onClick={generateReport}
                            disabled={isLoading}
                            style={{ minWidth: '250px'}}
                            >
                            Generate And Download Report
                        </button>
                            </div>

                        <div id="dragContainer" className="dragContainer" onDrop={(e) => drop(e)} onDragOver={allowDrop}>

                            <div className="graph">
                                <ExceedancesAndAlertsComp exceedanceData={props.exceedanceData} />
                            </div>

                            {props.segregatedData.map((val: any, index: any) =>
                                val.graphList.map((graph: any, i: any) => {
                                    return (<div className="graph" onDragStart={(e) => dragStart(e)} key={graph + val.sequenceId} onDragOver={(e) => drgOver(e)}
                                        id={"grapNo" + index + i} draggable="true" >
                                        <DraggableCard renderAgain={renderAgain} key={graph} data={val} graphName={graph} ></DraggableCard>
                                    </div>)
                                })
                            )}
                        </div>
                        {!props.isFileLoading && props.segregatedData.length === 0 &&
                            <div style={{ marginBottom: `45vh`, marginLeft: `30%`, color: `grey` }}>
                                Data Invalid for Phase Detection
                            </div>}
                    </div>
                </div>
                {/* } */}

                <SliderComp viewer={props.viewer} range={props.range} analysisData={props.analysisData} timeChangeData={props.currTimeValue} gotoPhase={gotoPhase} />
            </div>
        </>
    )
}
export const getSegData = () => {
    return segregatedDataCopy;
}
export const getTailNumber = () => {
    return tailNumber;
}
export const getStartTime = () => {
    if (startTime) {
        const startTimeDate = new Date(startTime);

        // Extract date components
        const day = startTimeDate.getDate().toString().padStart(2, '0');
        const month = (startTimeDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = startTimeDate.getFullYear();

        // Extract time components
        const hours = startTimeDate.getHours().toString().padStart(2, '0');
        const minutes = startTimeDate.getMinutes().toString().padStart(2, '0');
        const seconds = startTimeDate.getSeconds().toString().padStart(2, '0');

        // Construct the formatted string
        const formattedStartTime = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;

        // console.log(`Formatted startTime: `, formattedStartTime);
        return formattedStartTime;
    }
    return
}

// export const getStartTime = () => {
//     console.log(`startTime: `, startTime)
//     return startTime;
// }

export default React.memo(DebriefPlayer);