import axios from "axios";
import axiosInstance from "./baseService";
import { getLocalStorage } from "../common/utils/localStorageHandler";

const UPLOAD_API_AUTH_URL = process.env.REACT_APP_AUTHENTICATED_URL;
// REACT_APP_AUTHENTICATED_URL = http://192.168.2.253:7124/auth/api
const UPLOAD_API_URL = process.env.REACT_APP_AUTH_BASE_URL;
// http://192.168.2.253:7124/api

const generateUploadUrl = (payload: any) => {
    console.log(payload);
    const authToken = getLocalStorage("authTokens", "");

    // let formData = new FormData();
    // formData.append("fileurls", payload);

    // return axiosInstance.post(UPLOAD_API_URL + "/Debrief/UploadFolder?userId=" + authToken.userid, payload)
    return axiosInstance.post(UPLOAD_API_AUTH_URL + "/Debrief/UploadFolder", payload)
        .then((response) => {
            return response.data
        })
}

const saveToDb = (payload: any) => {

    return axiosInstance.post(UPLOAD_API_AUTH_URL + "/Debrief/SaveToDb", payload)
        .then((response) => {
            return response.data
        })
};

const updateDebriefLog = (fileInfo: any) => {
    return axiosInstance.post(UPLOAD_API_AUTH_URL + "/Debrief/UpdateDebriefLog", fileInfo, {
        headers: {
            //   "authToken": authToken.token,
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response;
        })
}

const parseArincData = (sessionId: any) => {
    let formData = new FormData();
    formData.append("sessionid", sessionId);

    // Return the promise created by axiosInstance.post
    return axiosInstance.post(UPLOAD_API_URL + "/HTT40/ParseArincData", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const UploadFolderService = {
    generateUploadUrl,
    saveToDb,
    updateDebriefLog,
    parseArincData,
};

export default UploadFolderService;